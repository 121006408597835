import React from "react";
import { useDispatch } from "react-redux";
import { setAppMessage } from "../Actions/appActions";

export default function Snackbar({ state }) {
  const dispatch = useDispatch();
  if (state?.message) {
    setTimeout(() => {
      dispatch(setAppMessage({}));
    }, 3000);
  }
  return (
    <div
      aria-live="polite"
      aria-atomic="true"
      data-autohide="false"
      data-animation="true"
      className={`snackbar-wrapper`}
      //   style={"position: relative; minHeight: 200px;"}
    >
      <div
        className={`toast  ${state?.message ? "show" : "false"} ${
          state?.type === "error" ? "error-alert" : "succes-alert"
        }`}
      >
        <div className="toast-body">{state?.message}</div>
      </div>
    </div>
  );
}
