import {
  GET_EVENTS_FAIL,
  GET_EVENTS_REQUEST,
  GET_EVENTS_SUCCESS,
} from "../constants/EventConstants";

export const createEventsReducer = (state = [], action) => {
  switch (action.type) {
    case GET_EVENTS_REQUEST:
      return { loading: true };
    case GET_EVENTS_SUCCESS:
      return { loading: false, data: action.payload };
    case GET_EVENTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
