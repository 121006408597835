import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
const UplaodImage = ({ setFormData, formData }) => {
  const initialValues = {
    taskName: formData?.taskName || "",

    contribution: formData?.contribution || "",
    pdf: formData?.pdf || "",
    startTime: "",
    endTime: "",
  };
  const [previewImg, setPreviewImg] = useState(
    formData.eventDetail.eventImage.length > 0
      ? formData.eventDetail.eventImage[0]
      : ""
  );
  const validationSchema = Yup.object({
    eventName: Yup.string().required("Pelase Enter the Event Name "),
    location: Yup.string().required("Pelase Enter the Location"),
  });

  // const handleChange = ;
  const removeImage = () => {
    let data = { ...formData };
    data.eventDetail.eventImage = [];
    setFormData(data);
    setPreviewImg("");
  };
  return (
    <div className="event-img pt-4 pe-lg-5">
      <div className="d-flex justify-content-between py-2">
        <div>Image</div>
        <div>
          {previewImg ? (
            <a
              onClick={removeImage}
              className="text-decoration-none remove-img-btn"
            >
              Remove
            </a>
          ) : null}
        </div>
      </div>
      <div className="d-block">
        <div className="main">
          <label htmlFor="eventImg" className="pe-2 rounded">
            <span className="upload-text">Upload</span>
            {previewImg ? (
              <img
                alt="event-img"
                className="uploadedimg rounded"
                src={previewImg}
              />
            ) : null}
          </label>
        </div>
        <div>
          <Formik
            initialValues={formData?.eventDetail}
            validationSchema={validationSchema}
          >
            {(formik) => {
              const { setFieldValue, values, handleChange, errors } = formik;

              // newEvent(values);
              return (
                <div className="addFormNew">
                  <div className="d-none">
                    <input
                      name="eventImg"
                      id="eventImg"
                      type="file"
                      accept="image/png, image/gif, image/jpeg, image/jpg"
                      onChange={(e) => {
                        // values.eventImage = window.URL.createObjectURL(e.target.files[0]);
                        let data = { ...formData };
                        data?.eventDetail?.eventImage.push(e.target.files[0]);
                        setFormData(data);

                        setPreviewImg(
                          window.URL.createObjectURL(e.target.files[0])
                        );
                        e.target.value = "";
                      }}
                      required
                    />
                  </div>
                </div>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default UplaodImage;
