import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import EventList from "../../Components/EventList";
import Loader from "../../Components/Loader";
import UpcomingEvent from "../../Components/UpcomingEvent";
import { eventStatus, EVENT_LIST_LENGTH } from "../../constant";
import { getEventTypeByValue } from "../../Helpers/Utils";
import { getWebEvents, getUpcomingEvent } from "../../Services/EventService";
import WelcomeNote from "../../Components/WelcomeNote";

const EventsList = () => {
  const [eventListPost, setEventListPost] = useState({});
  const [upcomingEvent, setUpcomingEvent] = useState([]);
  const [isFetchMore, setIsFetchMore] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [activeTab, setActiveTab] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [upcomingloader, setUpcomingLoader] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tabs = [
    {
      id: 1,
      name: "pills-applications",
      liName: "pills-applications-tab",
      isActive: true,
    },
    {
      id: 2,
      name: "pills-details",
      liName: "pills-details-tab",
      isActive: false,
    },
    {
      id: 3,
      name: "pills-Promote",
      liName: "pills-Promote-tab",
      isActive: false,
    },
    // { id: 3, name: "pills-media", liName: "pills-media-tab", isActive: false },
  ];

  useEffect(() => {
    getEvents(1, 0, pageNumber);
    getUpcomingEventData();
  }, []);
  const getEvents = async (organizationId, status, pageNumber) => {
    console.log("pageNumber", pageNumber);
    if (pageNumber === 1) {
      setIsLoading(true);
    }
    dispatch(
      getWebEvents(organizationId, status, pageNumber, (response) => {
        setIsLoading(false);
        if (response.success) {
          if (Object.keys(eventListPost).length === 0) {
            setEventListPost(response.result);
          } else {
            let list = { ...eventListPost };
            let eventLabel = getEventTypeByValue(status).toLowerCase();
            if (response.result[eventLabel].length === EVENT_LIST_LENGTH) {
              setHasMore(true);
            } else {
              setHasMore(false);
            }
            list[eventLabel] = list[eventLabel].concat(
              response.result[eventLabel]
            );
            setEventListPost(list);
          }
        }
      })
    );
  };
  const fetchMoreData = (status) => {
    setPageNumber(pageNumber + 1);
    getEvents(1, status, pageNumber + 1);
  };
  const getUpcomingEventData = async (eventId) => {
    setUpcomingLoader(true);
    let data = await getUpcomingEvent(eventId);
    console.log(data);
    setUpcomingEvent(data);
    setUpcomingLoader(false);
  };
  const gotoNewEvent = () => {
    if (window.google) {
      window.google = undefined;
    }
    navigate(`/newevent`);
  };
  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : !isLoading &&
        eventListPost?.draft?.length === 0 &&
        eventListPost?.past?.length === 0 &&
        eventListPost?.publish?.length === 0 ? (
        <WelcomeNote />
      ) : (
        <>
          {upcomingloader ? (
            <Loader />
          ) : upcomingEvent.result !== null ? (
            <UpcomingEvent upcomingEvent={upcomingEvent} />
          ) : null}

          <h3 className="page-Title">All Events</h3>
          <ul
            className="nav events-detail-nav nav-pills border-bottom border-s-0"
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item pe-4" role="presentation">
              <button
                className="nav-link active text-dark bg-white px-0"
                id="pills-applications-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-applications"
                type="button"
                role="tab"
                aria-controls="pills-applications"
                aria-selected="true"
                onClick={() => setActiveTab(1)}
              >
                Published
              </button>
            </li>
            <li className="nav-item pe-4" role="presentation">
              <button
                className="nav-link text-dark bg-white px-0"
                id="pills-details-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-details"
                type="button"
                role="tab"
                aria-controls="pills-details"
                aria-selected="false"
                onClick={() => setActiveTab(2)}
              >
                Drafts
              </button>
            </li>
            <li className="nav-item pe-4" role="presentation">
              <button
                className="nav-link text-dark bg-white px-0"
                id="pills-Promote-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-Promote"
                type="button"
                role="tab"
                aria-controls="pills-Promote"
                aria-selected="false"
                onClick={() => setActiveTab(3)}
              >
                Past events
              </button>
            </li>
            <div className="ms-auto bg-white pt-2">
              <a
                href={"javascript:void(0)"}
                // to={`/newevent`}
                className="text-decoration-none new-event-btn ps-4"
                onClick={gotoNewEvent}
              >
                + New event
              </a>
            </div>
          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="event-detail-table tab-pane fade show active py-5"
              id="pills-applications"
              role="tabpanel"
              aria-labelledby="pills-applications-tab"
            >
              <div>
                {activeTab === 1 && (
                  <EventList
                    isFetchMore={isFetchMore}
                    fetchMoreData={fetchMoreData}
                    events={eventListPost?.publish || []}
                    type={"publish"}
                    isLoading={isLoading}
                    hasMore={hasMore}
                    setHasMore={setHasMore}
                  />
                )}
              </div>
            </div>
            <div
              className="tab-pane fade py-5 event-detail-table"
              id="pills-details"
              role="tabpanel"
              aria-labelledby="pills-details-tab"
            >
              <div>
                {activeTab === 2 && (
                  <EventList
                    isFetchMore={isFetchMore}
                    fetchMoreData={fetchMoreData}
                    events={eventListPost?.draft || []}
                    type={"draft"}
                    isLoading={isLoading}
                    hasMore={hasMore}
                    setHasMore={setHasMore}
                  />
                )}
              </div>
            </div>
            <div
              className="tab-pane fade py-5 event-detail-table"
              id="pills-Promote"
              role="tabpanel"
              aria-labelledby="pills-Promote-tab"
            >
              <div>
                {activeTab === 3 && (
                  <EventList
                    isFetchMore={isFetchMore}
                    fetchMoreData={fetchMoreData}
                    events={eventListPost?.past || []}
                    type={"past"}
                    isLoading={isLoading}
                    hasMore={hasMore}
                    setHasMore={setHasMore}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EventsList;
