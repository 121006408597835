import { setAppMessage } from "../Actions/appActions";
import { getProfileFromStorage } from "../Helpers/Utils";
import { getToken } from "../Helpers/customHooks";
export function changePassword(data) {
  try {
    return new Promise((resolve, reject) => {
      let token = getToken();

      const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: `Bearer ${token}`,
        },
      };
      fetch(
        process.env.REACT_APP_API_URL + "User/ChangePassword",
        requestOptions
      )
        .then((response) => response.json())
        .then((response) => {
          resolve(response);
        });
    });
  } catch (ex) {}
}
