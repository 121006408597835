import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setAppMessage } from "../../Actions/appActions";
import { setProfileAction, setTokenAction } from "../../Actions/profileAction";
import Loader from "../../Components/Loader";
import { changePassword } from "../../Services/AccountService";
import { getAccountDetailById } from "../../Services/EventService";

const Settings = () => {
  const [account, setAccount] = useState({
    // organizationName: "American Rivers Foundation",
    // fullName: "John Doe",
    // email: "doe@organization.com",
    // password: "password123",
  });
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getAccountDetail();
  }, []);

  const getAccountDetail = async () => {
    setIsLoader(true);
    let data = await getAccountDetailById();
    console.log(data);
    // setNewPassword(data.result.password);
    setAccount(data);
    setIsLoader(false);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const valdation = () => {
    if (newPassword === "") {
      setMessage("Password is required.");
      return false;
    } else if (newPassword.length < 6) {
      setMessage("Password should be minimum 6 chars.");
      return false;
    } else {
      return true;
    }
  };
  const updatePassword = async () => {
    setIsLoading(true);
    let isValid = valdation();
    if (isValid) {
      let data = {
        currentPassword: account?.result?.password,
        newPassword: newPassword,
      };
      let res = await changePassword(data);
      if (res.success) {
        dispatch(
          setAppMessage({
            success: true,
            component: "changePassword",
            type: "success",
            message: "Password has been change successfully!",
          })
        );
      } else {
        dispatch(
          setAppMessage({
            success: false,
            component: "changePassword",
            type: "error",
            message: res.error.details || res.error.message,
          })
        );
      }
      setIsChangePassword(false);
    }
    setIsLoading(false);
  };
  return (
    <div className="profile-settings">
      <h3 className="page-Title">Account</h3>
      <ul
        className="nav events-nav nav-pills border-bottom border-s-0"
        id="pills-tab"
        role="tablist"
      >
        <li className="nav-item pe-4" role="presentation">
          <button
            className="nav-link active text-dark bg-white px-0"
            id="pills-profile-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-profile"
            type="button"
            role="tab"
            aria-controls="pills-profile"
            aria-selected="true"
          >
            My details
          </button>
        </li>
        <li className="nav-item pe-4" role="presentation">
          <button
            className="nav-link text-dark bg-white px-0"
            id="pills-Preferences-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-Preferences"
            type="button"
            role="tab"
            aria-controls="pills-Preferences"
            aria-selected="false"
          >
            Organization details
          </button>
        </li>

        <div className="ms-auto new-event-btn bg-white pt-2">
          <a
            className="text-decoration-none"
            onClick={(e) => {
              e.preventDefault();
              localStorage.clear();
              dispatch(setProfileAction({}));
              dispatch(setTokenAction(""));
              navigate("/signin");
            }}
          >
            Log out
          </a>
        </div>
      </ul>
      <div className="tab-content" id="pills-tabContent">
        <div
          className="tab-pane fade show active py-5"
          id="pills-profile"
          role="tabpanel"
          aria-labelledby="pills-profile-tab"
        >
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-5">
              {isLoader ? (
                <Loader />
              ) : (
                <div>
                  <Form>
                    <Form.Group className="mb-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        className="p-3"
                        type="text"
                        defaultValue={account?.result?.name}
                        value={account?.result?.name}
                        disabled={true}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        className="p-3"
                        type="email"
                        defaultValue={account?.result?.emailAddress}
                        disabled={true}
                        value={account?.result?.emailAddress}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3 position-relative">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        className="p-3"
                        type="password"
                        placeholder="Enter new password"
                        disabled={!isChangePassword}
                        // defaultValue={"XXXXX"}
                        value={isChangePassword ? newPassword : "XXXXXX"}
                        onChange={(e) => {
                          setNewPassword(e.currentTarget.value);
                          setMessage("");
                        }}
                      />
                      <div className="change-pass">
                        {isChangePassword ? (
                          <>
                            <a
                              onClick={(e) => {
                                e.preventDefault();
                                updatePassword();
                              }}
                              disabled={isLoading}
                              className="btn-event text-link-color me-2 text-decoration-none"
                            >
                              Update
                            </a>
                            <a
                              onClick={(e) => {
                                e.preventDefault();
                                setIsChangePassword(false);
                                setNewPassword("");
                                setMessage("");
                              }}
                            >
                              Cancel
                            </a>
                            {isLoading && (
                              <div
                                className="spinner-border text-danger ms-2"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            )}
                          </>
                        ) : (
                          <a
                            onClick={(e) => {
                              e.preventDefault();
                              setIsChangePassword(true);
                            }}
                          >
                            Change
                          </a>
                        )}
                      </div>
                      {message && <div className="error">{message}</div>}
                    </Form.Group>
                  </Form>
                </div>
              )}
            </div>
            <div className="col-12 col-md-1 col-lg-2"></div>
            <div className="col-12 col-sm-12 col-md-5 col-lg-5"></div>
          </div>
        </div>
        <div
          className="tab-pane fade py-5"
          id="pills-Preferences"
          role="tabpanel"
          aria-labelledby="pills-Preferences-tab"
        >
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-5">
              {isLoader ? (
                <Loader />
              ) : (
                <Form.Group className="mb-3">
                  <Form.Label>Organization name</Form.Label>
                  <Form.Control
                    className="p-3"
                    type="text"
                    defaultValue={account?.result?.organizationName}
                  />
                </Form.Group>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
