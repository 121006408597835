import React, { useState, useRef, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "./FormikControl";
import { Link, useNavigate } from "react-router-dom";
import MessageBox from "../Components/MessageBox";
import { loginService, registrationService } from "../Services/LoginServices";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "../Components/Snackbar";
import { setErrorAction } from "../Actions/appActions";

function RegistrationForm() {
  const inputRef = useRef();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    organizationName: "",
    emailAddress: "",
    password: "",
    name: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialValues = {
    organizationName: "",
    emailAddress: "",
    password: "",
    name: "",
  };

  const validationSchema = Yup.object({
    organizationName: Yup.string().required("Organization name is required."),
    name: Yup.string().required("Name is required."),
    emailAddress: Yup.string()
      .email("Invalid email.")
      .required("Email is Required."),
    password: Yup.string()
      .required("Password is Required.")
      .min(6, "Password should be minimum 6 chars."),
    // address: Yup.string().required("Address is Required"),
  });
  const navigateToDashboard = () => {
    setIsLoading(false);
    navigate("/", { replace: true });
  };
  const onSubmit = async (values) => {
    setIsLoading(true);
    dispatch(
      registrationService(values, (response) => {
        if (response.success) {
          dispatch(
            loginService(
              {
                emailAddress: values.emailAddress,
                password: values.password,
                rememberClient: true,
              },
              (success) => {
                if (success) {
                  navigateToDashboard();
                } else {
                  setIsLoading(false);
                }
              }
            )
          );
        } else {
          setIsLoading(false);
        }
      })
    );
  };
  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      let data = { ...formData };
      data.address = place.formatted_address;
      data.latitude = place.geometry.location.lat();
      data.longitude = place.geometry.location.lng();
      data.postalCode = place.address_components.find((component) =>
        component.types.includes("postal_code")
      ).long_name;
      data.state = place.address_components.find((component) =>
        component.types.includes("administrative_area_level_1")
      ).long_name;
      data.city = place.address_components.find((component) =>
        component.types.includes("locality")
      ).long_name;
      setFormData(data);
      setLat(place.geometry.location.lat());
      setLong(place.geometry.location.lng());
    }
  };
  const containerStyle = {
    marginTop: "20px",
    width: "100%",
    height: "250px",
  };
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  // const center = {
  //   lat: 30.1911154,
  //   lng: 71.4411123,
  // };
  const [map, setMap] = React.useState(null);
  const user = useSelector((state) => state.profileReducer);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds({
      lat: lat,
      lng: long,
    });
    map.fitBounds(bounds);

    setMap(map);
  }, []);
  useEffect(() => {
    if (user.profile && Object.keys(user.profile).length > 0) {
      navigate("/", { replace: true });
    }
  }, []);
  return (
    <>
      {error && <MessageBox variant="danger">{error}</MessageBox>}
      {message && <MessageBox variant="success">{message}</MessageBox>}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form className="register-form px-xl-5">
              <div className="row">
                <div className="col-sm-12 col-md-2 col-lg-3"></div>
                <div className="col-sm-12 col-md-8 col-lg-6 px-xl-5">
                  <h3 className="page-Title">Sign Up</h3>

                  <div className="form-group mt-3">
                    <FormikControl
                      control="input"
                      type="text"
                      label="Organization name"
                      name="organizationName"
                      // value={formData.organizationName}
                      className="form-control p-3"
                      placeholder="Organization name..."
                      // onChange={(e) => {
                      //   let data = { ...formData };
                      //   data.organizationName = e.target.value;
                      //   setFormData(data);
                      // }}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <FormikControl
                      control="input"
                      type="text"
                      label="Your name"
                      name="name"
                      // value={formData.name}
                      className="form-control p-3"
                      placeholder="First and last name..."
                      // onChange={(e) => {
                      //   let data = { ...formData };
                      //   data.name = e.target.value;
                      //   setFormData(data);
                      // }}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <FormikControl
                      control="input"
                      type="email"
                      label="Email"
                      name="emailAddress"
                      // value={formData.emailAddress}
                      className="form-control p-3"
                      placeholder="Your email..."
                      // onChange={(e) => {
                      //   let data = { ...formData };
                      //   data.emailAddress = e.target.value;
                      //   setFormData(data);
                      // }}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <FormikControl
                      control="input"
                      label="Create a password"
                      type="password"
                      name="password"
                      placeholder="Password..."
                      className="form-control p-3"
                      // value={formData.password}
                      // onChange={(e) => {
                      //   let data = { ...formData };
                      //   data.password = e.target.value;
                      //   setFormData(data);
                      // }}
                    />
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>
                      <button
                        className="register-btn mt-3"
                        type="submit"
                        disabled={!formik.isValid && isLoading}
                      >
                        Sign up
                        {isLoading && (
                          <div
                            className="spinner-border text-white ms-2"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        )}
                      </button>
                    </div>
                    <div className="mt-4">
                      Already have an account?
                      <Link
                        className="login-btn mt-3 text-decoration-none"
                        to={"/signin"}
                      >
                        Login
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-2 col-lg-3"></div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default RegistrationForm;
