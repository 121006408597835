import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "./FormikControl";
import { Link, useNavigate } from "react-router-dom";
import MessageBox from "../Components/MessageBox";
import {
  loginService,
  LoginService,
  ForgetPassword,
} from "../Services/LoginServices";
import { useDispatch, useSelector } from "react-redux";
import { validateEmail } from "../Helpers/Utils";

function LoginForm() {
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isForogtLoading, setIsForgotLoading] = useState(false);
  const user = useSelector((state) => state.profileReducer);
  const [forgetPassword, setForgetPassword] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Please enter an email"),
    password: Yup.string()
      .required("Please enter your password")
      .min(6, "Password is too short - should be 6 chars minimum"),
  });
  const navigateToDashboard = (isSuccess) => {
    setIsLoading(false);
    if (isSuccess) {
      navigate("/", { replace: true });
    }
  };
  const onSubmit = async (values, actions) => {
    setIsLoading(true);
    let data = dispatch(
      loginService(
        {
          emailAddress: values.email,
          password: values.password,
          rememberClient: true,
        },
        (isSuccess) => navigateToDashboard(isSuccess)
      )
    );
  };
  useEffect(() => {
    if (user.profile && Object.keys(user.profile).length > 0) {
      navigate("/", { replace: true });
    }
  }, []);
  const GetPasswordByEmail = async () => {
    setIsForgotLoading(true);
    dispatch(
      ForgetPassword(forgetPassword, (response) => {
        setIsForgotLoading(false);
        if (response.success) {
          setForgetPassword(null);
          window.jQuery("#forgetPasswordModal").modal("hide");
        }
      })
    );
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form className="login-form px-xl-5">
              <div className="row">
                <div className="col-sm-12 col-md-2 col-lg-3"></div>
                <div className="col-sm-12 col-md-8 col-lg-6">
                  <div className="px-xl-5">
                    <h3 className="page-Title">Login</h3>
                    <div className="form-group mt-3">
                      <FormikControl
                        control="input"
                        type="email"
                        label="Email"
                        name="email"
                        placeholder="Email"
                        className="form-control p-3"
                      />
                    </div>
                    <div className="form-group mt-3 position-relative">
                      <FormikControl
                        control="input"
                        type="password"
                        label="Password"
                        name="password"
                        placeholder="Password"
                        className="form-control p-3"
                      />
                      <div className="forget-pass">
                        <a
                          data-bs-toggle="modal"
                          data-bs-target="#forgetPasswordModal"
                        >
                          {" "}
                          Forgot?
                        </a>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div>
                        <button
                          className="login-btn mt-3"
                          type="submit"
                          disabled={!formik.isValid && isLoading}
                        >
                          Login
                          {isLoading && (
                            <div
                              className="spinner-border text-white ms-2"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          )}
                        </button>
                      </div>
                      <div className="mt-4">
                        Don't have an account yet?
                        <Link
                          className="sign-btn mt-3 text-decoration-none"
                          to={"/register"}
                        >
                          Create one
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-2 col-lg-3"></div>
              </div>

              <div
                className="modal fade"
                id="forgetPasswordModal"
                tabIndex="-1"
                aria-labelledby="forgetPasswordModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="forgetPasswordModalLabel">
                        Forget Password?
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="form-group">
                        <label className="py-2">
                          Please enter your email address
                        </label>
                        <input
                          className="form-control py-3"
                          type="email"
                          name="emailAddress"
                          placeholder="Enter email address"
                          id="title"
                          onChange={(e) => {
                            setForgetPassword(e.target.value);
                          }}
                        ></input>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        onClick={() => GetPasswordByEmail()}
                        type="button"
                        className="login-btn"
                        disabled={
                          isForogtLoading || !validateEmail(forgetPassword)
                        }
                      >
                        Send email
                        {isForogtLoading && (
                          <div
                            className="spinner-border text-white ms-2"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default LoginForm;
