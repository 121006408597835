import React from 'react'
import { Link } from 'react-router-dom'

const WelcomeNote = () => {
  return (
    <div className='container welcome-note'>
        <h4 className='page-Title'>Welcome to Vero!</h4>
        <p className='pb-4'>Start by creating your first volunteering event</p>
        <Link to={`/newevent`} className='create-event-btn'>Create event</Link>
      <p></p>
    </div>
  )
}

export default WelcomeNote
