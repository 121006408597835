import React, { useState } from "react";
import { getMediaGallery, postMediaImage } from "../Services/EventService";

const MediaUploadImage = ({
  placeHolderImage,
  removeImage,
  callback,
  setImageToUpload,
  setMediaImages,
  imageToUpload,
}) => {
  const [imagePreview, setImagePreview] = useState("");

  const imageUpload = async (e) => {
    if (e?.target?.files) {
      callback(window.URL.createObjectURL(e.target.files[0]));
      await postMediaImage(e.target.files[0]);
      e.target.files = null;
      e.target.value = null;
      const allMediaImages = await getMediaGallery();
      setImageToUpload(null);
      setMediaImages(allMediaImages.result.items);
    }
  };
  return (
    <div>
      <div className="image">
        <div className="main">
          <label htmlFor="image" className="pe-2">
            {/* {imagePreview ? (
            <img alt="event-img" className="uploadedimg" src={imagePreview} />
          ) : ( */}
            <img
              alt="event-img"
              className="uploadedimg"
              src={placeHolderImage}
            />
            {/* )} */}
          </label>
        </div>
        <div className="d-none">
          <input
            name="image"
            id="image"
            type="file"
            accept="image/png, image/gif, image/jpeg, image/jpg"
            onChange={imageUpload}
            disabled={imageToUpload !== null ? true : false}
          />
        </div>
      </div>
    </div>
  );
};

export default MediaUploadImage;
