import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import assetsImage from "../assets/assets";
import UplaodImage from "./UploadImage";
import MediaUploadImage from "./MediaUploadImage";
import Loader from "./Loader";
import {
  deleteGalleryImage,
  getMediaGallery,
  postMediaImage,
} from "../Services/EventService";

const Media = ({ formData, setFormData }) => {
  useEffect(() => {
    getAllMedia();
  }, []);

  const [mediaImages, setMediaImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState({});
  const [uploadImageLoading, setUploadImageLoading] = useState(false);
  const [imageToUpload, setImageToUpload] = useState(null);
  const [deleteImageLoader, setDeleteImageLoader] = useState(false);

  async function getAllMedia() {
    const allMediaImages = await getMediaGallery();
    setMediaImages(allMediaImages.result.items);
  }

  console.log(mediaImages);

  useEffect(() => {
    console.log(selectedImage);
    debugger;
    if (Object.keys(selectedImage).length !== 0) {
      let data = { ...formData };
      data?.eventDetail?.eventImage.splice(
        0,
        data?.eventDetail?.eventImage?.length,
        selectedImage?.mediaUrl
      );
      setFormData(data);
    }
  }, [selectedImage]);
  const [imageLoading, setImageLoading] = useState(false);

  const imageUplaod = (file) => {
    console.log(file);
    let uploadedImage = {
      id: 22,
      mediaUrl: file,
      isSelected: false,
      isLoading: true,
    };
    setImageToUpload(uploadedImage);
  };
  const selectedPreviewImage = (image) => {
    if (image.isLoading) {
      return;
    }
    let cloneMediaImages = [...mediaImages];
    console.log(cloneMediaImages);
    let preSelectedImageIndex = cloneMediaImages.findIndex((x) => x.isSelected);
    cloneMediaImages[preSelectedImageIndex].isSelected = false;
    let selectedImageIndex = cloneMediaImages.findIndex(
      (x) => x.id === image.id
    );
    cloneMediaImages[selectedImageIndex].isSelected = true;
    setMediaImages(cloneMediaImages);
    setSelectedImage(image);
  };

  const removeGalleryImage = async (item, index) => {
    debugger;
    setDeleteImageLoader(true);
    await deleteGalleryImage(item.id);
    await getAllMedia();
    setSelectedImage(mediaImages[index + 1]);
    setDeleteImageLoader(false);
  };
  const selectedImageFun = (item) => {
    debugger;
    setSelectedImage(item);
  };
  return (
    <div>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
          <div>
            <h2 className="select-define mt-4">Media gallery </h2>
            <div className="custom-card new-Media ">
              <div className="scrollbar " id="style-1">
                <div className="force-overflow"></div>
                <ul className="mapping media-gallery">
                  <div
                    className={`media-gallery-absoluter ${
                      deleteImageLoader && "show"
                    }`}
                  >
                    <Loader />
                  </div>
                  <li className="d-inline-block">
                    <MediaUploadImage
                      imageToUpload={imageToUpload}
                      placeHolderImage={assetsImage.Plus}
                      callback={imageUplaod}
                      setImageToUpload={setImageToUpload}
                      setMediaImages={setMediaImages}
                    />
                  </li>
                  {imageToUpload !== null && (
                    <li
                      className={`d-inline-block media-image ${
                        imageToUpload !== null && "show"
                      }`}
                    >
                      <div className="image-absoluter">
                        <Loader />
                      </div>
                      <div className="image">
                        <img src={imageToUpload?.mediaUrl} alt="" />
                      </div>
                    </li>
                  )}
                  {mediaImages?.length > 0 &&
                    mediaImages?.map((item, i) => {
                      return (
                        <li
                          key={i}
                          className={`d-inline-block media-image ${
                            "isLoading" in item && item.isLoading && "show"
                          }`}
                          onClick={() => {
                            selectedImageFun(item);
                          }}
                        >
                          {item.isDefault == false && (
                            <span
                              className="event-image-button"
                              onClick={() => removeGalleryImage(item, i)}
                            >
                              X
                            </span>
                          )}
                          <div className="image-absoluter">
                            <Loader />
                          </div>
                          <div className="image">
                            <img
                              src={
                                process.env.REACT_APP_IMAGES_API_URL +
                                item.mediaUrl
                              }
                              alt=""
                            />
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 ps-lg-5">
          <div className="row">
            <div className="col-lg-2 col-md-1"></div>
            <div className="col-lg-8 col-md-11">
              <div className="drag-img text-center">
                <div className="drag-img-bg position-relative mt-3">
                  <img src={assetsImage.mobilePreviewImg} alt="" />
                  <div className="preview-img">
                    <img
                      src={
                        formData?.eventDetail?.eventImage?.length == 0 &&
                        Object.keys(selectedImage).length == 0
                          ? process.env.REACT_APP_IMAGES_API_URL +
                            mediaImages[0]?.mediaUrl
                          : Object.keys(selectedImage).length == 0
                          ? process.env.REACT_APP_IMAGES_API_URL +
                            formData?.eventDetail?.eventImage[0]
                          : process.env.REACT_APP_IMAGES_API_URL +
                            selectedImage?.mediaUrl
                      }
                      alt=""
                      className="selected-img"
                    />
                  </div>
                  <div className="mobile-preview-top">
                    <img src={assetsImage.MobilePreviewTop} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Media;
