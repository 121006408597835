import React, { useEffect, useState } from "react";
import Scheduler, {
  SchedulerData,
  ViewTypes,
  DATE_FORMAT,
  DemoData,
} from "react-big-scheduler";
import { Dropdown } from "react-bootstrap";
import Select from "react-select";
//include `react-big-scheduler/lib/css/style.css` for styles, link it in html or import it here
import "react-big-scheduler/lib/css/style.css";
import moment from "moment";
import withDragDropContext from "./DragDropContext";

// import { Calendar, momentLocalizer } from "react-big-calendar";
// import moment from "moment";
// import "react-big-calendar/lib/css/react-big-calendar.css";
let resources = [
  {
    id: "r0",
    name: "Resource0",
  },
  {
    id: "r1",
    name: "Resource1",
  },
  {
    id: "r2",
    name: "Resource2",
  },
];
let events = [
  {
    id: 1,
    start: "2017-12-18 00:00:00",
    end: "2017-12-18 08:00:00",
    resourceId: "r0",
    title: "Attend bar 0/2",
    bgColor: "#DFF7E3",
    // if the number of task will be greater than 0 then the background color should be  #03A61D
  },
  {
    id: 2,
    start: "2017-12-18 08:00:00",
    end: "2017-12-18 18:00:00",
    resourceId: "r0",
    title: "Attend bar 8/10",
    bgColor: "#DFF7E3",
  },
  {
    id: 3,
    start: "2017-12-18 00:00:00",
    end: "2017-12-18 08:00:00",
    resourceId: "r1",
    title: "Raise funds 2/2",
    bgColor: "#DFF7E3",
  },
  {
    id: 4,
    start: "2017-12-18 08:00:00",
    end: "2017-12-18 16:00:00",
    resourceId: "r1",
    title: "Raise funds 0/2",
    bgColor: "#FFE5E3",
  },
  {
    id: 5,
    start: "2017-12-18 00:00:00",
    end: "2017-12-18 17:00:00",
    resourceId: "r2",
    title: "Cashier 0/2",
    bgColor: "#FFE5E3",
    Color: "red",
  },
  // {
  //   id: 3,
  //   start: "2017-12-18 07:30:00",
  //   end: "2017-12-18 15:30:00",
  //   resourceId: "r1",
  //   title: "I am not resizable",
  //   resizable: false,
  //   bgColor: "#FFE5E3",
  // },
  // {
  //   id: 4,
  //   start: "2017-12-19 12:30:00",
  //   end: "2017-12-20 23:30:00",
  //   resourceId: "r3",
  //   title: "I am not movable",
  //   movable: false,
  // },
  // {
  //   id: 5,
  //   start: "2017-12-19 14:30:00",
  //   end: "2017-12-20 23:30:00",
  //   resourceId: "r1",
  //   title: "I am not start-resizable",
  //   startResizable: false,
  // },
  // {
  //   id: 6,
  //   start: "2017-12-19 15:30:00",
  //   end: "2017-12-20 23:30:00",
  //   resourceId: "r2",
  //   title: "R2 has recurring tasks every week on Tuesday, Friday",
  //   rrule: "FREQ=WEEKLY;DTSTART=20171219T013000Z;BYDAY=TU,FR",
  //   bgColor: "#f759ab",
  // },
];

const TaskCalendar = ({
  eventApplications,
  setSelectedEvent,
  selectedEvent,
  setEventApplications,
  eventSelectedDate,
  setEventSelectedDate,
}) => {
  const [isFilterActive, setFilterActive] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    eventApplications?.events[0]?.eventDate
  );

  useEffect(() => {
    console.log(selectedEvent);
  }, [selectedEvent]);

  let schedulerData = new SchedulerData(
    eventApplications?.events[0]?.eventDate,
    ViewTypes.Day,
    false,
    false,
    {
      schedulerWidth:
        window.innerWidth < 576
          ? "100%"
          : window.innerWidth >= 576 && window.innerWidth < 768
          ? 650
          : window.innerWidth >= 768 && window.innerWidth < 992
          ? 830
          : window.innerWidth >= 992 && window.innerWidth < 1200
          ? 1060
          : window.innerWidth >= 1200 && window.innerWidth < 1400
          ? 1250
          : 1430,
    }
  );
  schedulerData.localeMoment.locale("en");
  schedulerData.setResources(eventApplications?.resources);
  schedulerData.setEvents(eventApplications?.events);
  console.log("eventApplications?.events", eventApplications?.events);
  console.log("eventApplications?.resources", eventApplications?.resources);
  console.log("eventApplications?.schedulerData", schedulerData);
  const caledarDates = () => {
    let dates = [];
    console.log(" eventApplications.eventDate", eventApplications.eventDate);
    eventApplications.eventDate.map((d) => {
      let obj = {
        label: moment(d).format("LL"),
        value: moment(d).format("LL"),
      };
      dates.push(obj);
    });
    return dates;
  };
  let options = caledarDates();
  // const localizer = momentLocalizer(moment); // or globalizeLocalizer
  const [selectedOption, setSelectedOption] = useState(null);
  const filterClick = (event) => {
    setFilterActive((current) => !current);
  };
  const eventClicked = (schedulerData, event) => {
    let isEventSelected = selectedEvent.findIndex((x) => x.id === event.id);
    if (isEventSelected === -1) {
      let eventApplicationsClone = { ...eventApplications };
      let findEvent = eventApplicationsClone.events.find(
        (x) => x.id === event.id
      );
      if (findEvent) {
        findEvent.isSelected = true;
        eventApplicationsClone.events[findEvent] = findEvent;
      }
      setEventApplications(eventApplicationsClone);
      let findEventWithTaskID = selectedEvent.find(
        (e) => e.taskSessionId == event.taskSessionId
      );
      if (findEventWithTaskID) {
        return;
      } else {
        setSelectedEvent([...selectedEvent, event]);
      }
    }
  };
  const eventItemTemplateResolver = (
    schedulerData,
    event,
    bgColor,
    isStart,
    isEnd,
    mustAddCssClass,
    mustBeHeight,
    agendaMaxEventWidth
  ) => {
    let findEvent = selectedEvent.find(
      (item) => item.taskSessionId == event.taskSessionId
    );
    if (findEvent) {
      event.isSelected = true;
    } else {
      event.isSelected = false;
    }
    let divStyle = "";
    if (event.isSelected) {
      divStyle = {
        backgroundColor:
          event.applyVolunteerCount === 0
            ? "#FF1B11"
            : event.applyVolunteerCount < event.maxVolunteerCount &&
              event.applyVolunteerCount > 0
            ? "#FF7900"
            : "#03A61D",
        color: "#fffff",
        borderRadius: "5px",
      };
    } else {
      divStyle = {
        backgroundColor:
          event.applyVolunteerCount === 0
            ? "#FFE5E3"
            : event.applyVolunteerCount < event.maxVolunteerCount &&
              event.applyVolunteerCount > 0
            ? "#FFEDCB"
            : "#DFF7E3",
        color:
          event.applyVolunteerCount === 0
            ? "#FF1B11"
            : event.applyVolunteerCount < event.maxVolunteerCount &&
              event.applyVolunteerCount > 0
            ? "#FF7900"
            : "#03A61D",
        borderRadius: "5px",
      };
    }

    let eventClassName =
      event.applyVolunteerCount === 0
        ? "intial"
        : event.applyVolunteerCount < event.maxVolunteerCount &&
          event.applyVolunteerCount > 0
        ? "medium"
        : "complete";
    return (
      <div
        key={event.id}
        className={
          mustAddCssClass + " " + eventClassName + " " + event.isSelected
        }
        style={divStyle}
      >
        <span style={{ marginLeft: "4px", lineHeight: `${mustBeHeight}px` }}>
          {event.title}
        </span>
      </div>
    );
  };
  return (
    <div className="container task-calendar py-4 px-0">
      <div className="row">
        <div className="col-sm-12 col-md-5 col-lg-4 col-xl-3 px-0">
          <Select
            styles={{
              backgroundColor: "white",
              control: (provided, state) => ({
                ...provided,
                padding: "0px",
                marginBlock: "0px",
                backgroundColor: "white",
                border: "none",
                height: "auto",
                border: 0,
                zIndex: 99999,
              }),
              option: (provided, state) => ({
                ...provided,
                fontWeight: state.isSelected ? "bold" : "normal",
                borderTop: "2px solid white",
                backgroundColor: state.isFocused ? "black" : "white",
                backgroundColor: state.isFocused ? "black" : "white",
                color: state.isFocused ? "white" : "black",
                border: "none",
                fontSize: state.selectProps.myFontSize,
                ":active": {
                  ...provided[":active"],
                  backgroundColor: "black",
                },
              }),
              singleValue: (provided, state) => ({
                ...provided,
                color: "black",
                fontSize: state.selectProps.myFontSize,
              }),
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: "hotpink",
                primary: "black",
              },
            })}
            defaultValue={{
              label: moment(selectedDate).format("LL"),
              value: moment(selectedDate).format("LL"),
            }}
            onChange={(date) => {
              setEventSelectedDate(date?.value);
              setSelectedDate(date?.value);
            }}
            options={options}
          />
        </div>
      </div>
      <div className="calendar-container">
        <Scheduler
          schedulerData={schedulerData}
          prevClick={() => {}}
          nextClick={() => {}}
          onSelectDate={() => {}}
          onViewChange={() => {}}
          eventItemClick={eventClicked}
          eventItemTemplateResolver={eventItemTemplateResolver}
        />
      </div>
    </div>
  );
};

export default withDragDropContext(TaskCalendar);
