import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { createEventsReducer } from "./Reducers/EventReducer";
import { profileReducer } from "./Reducers/profileReducer";
import { appReducer } from "./Reducers/appReducer";

const initialState = {};
const reducer = combineReducers({
  EventsReducer: createEventsReducer,
  profileReducer: profileReducer,
  appReducer: appReducer,
});
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);

export default store;
