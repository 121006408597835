import CustomNavbar from "./Components/VeroNavbar";
import { Route, Routes } from "react-router-dom";
import Setting from "./Pages/Setting/Setting";
import Dashboard from "./Pages/Dashboard/Dashboard";
import EditEvent from "./Pages/Event/EditEvent.js";
import NewEvent from "./Pages/Event/NewEvent";
import NewEventTabs from "./Pages/Event/NewEventTabs";

// STYLE
import "./css/App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import EventsList from "./Pages/Event/EventsList";
import LoginForm from "./Validation/LoginForm";
import RegistrationForm from "./Validation/RegistrationForm";
import Protected from "./routes/protectedRoutes";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setProfileAction, setTokenAction } from "./Actions/profileAction";
import Template404 from "./Pages/404";
import Snackbar from "./Components/Snackbar";
import ViewEvent from "./Pages/Event/viewEvent";
import ForgotPassword from "./Pages/ForgotPassword";

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.profileReducer);
  const appState = useSelector((state) => state.appReducer);
  return (
    <div>
      <CustomNavbar />
      <div className="container py-4">
        <Routes>
          {/* <Route
            path="/"
            element={
              <Protected
                isLoggedIn={Object.keys(user.profile).length > 0 ? true : false}
              >
                <Dashboard />
              </Protected>
            }
          /> */}
          <Route
            path="/"
            element={
              <Protected
                isLoggedIn={Object.keys(user.profile).length > 0 ? true : false}
              >
                <EventsList />
              </Protected>
            }
          />
          <Route
            path="/account"
            element={
              <Protected
                isLoggedIn={Object.keys(user.profile).length > 0 ? true : false}
              >
                <Setting />
              </Protected>
            }
          />
          <Route
            path="/event/detail/:id"
            element={
              <Protected
                isLoggedIn={Object.keys(user.profile).length > 0 ? true : false}
              >
                <ViewEvent />
              </Protected>
            }
          />
          <Route
            path="/event/edit/:id"
            element={
              <Protected
                isLoggedIn={Object.keys(user.profile).length > 0 ? true : false}
              >
                <EditEvent />
              </Protected>
            }
          />
          <Route
            path="/newevent"
            element={
              <Protected
                isLoggedIn={Object.keys(user.profile).length > 0 ? true : false}
              >
                <NewEventTabs />
              </Protected>
            }
          />
          <Route path="/signin" element={<LoginForm />} />
          <Route path="/register" element={<RegistrationForm />} />
          <Route
            path="/reset-password/:email/:hash"
            element={<ForgotPassword />}
          />
          <Route path="*" element={<Template404 />} />
        </Routes>
        <Snackbar state={appState?.message} />
      </div>
    </div>
  );
}

export default App;
