import _ from "lodash";
import moment from "moment";
import { eventStatus } from "../constant";

export function fileConvertToBase64(file) {
  var img = new Image();
  img.src = window.URL.createObjectURL(file);
  return new Promise((resolve, reject) => {
    img.onload = () => {
      window.URL.revokeObjectURL(img.src);
      var reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target.result);
      };
      reader.readAsDataURL(file);
    };
  });
}
export function dateDifference(fromDate, toDate) {
  var duration = moment.duration(toDate.diff(fromDate));
  return duration.asDays();
}

export function monthDifference(fromDate, toDate) {
  var monthDifference = moment.duration(toDate.diff(fromDate), "months", true);
  return monthDifference;
}

export function getDayFromDate(date) {
  var momentDate = moment(date);
  return momentDate.day();
}

export function checkSelectedExistInSameWeek(dates) {
  moment.updateLocale("en", {
    week: {
      dow: 1, // Monday is the first day of the week.
    },
  });
  if (dates.length > 0) {
    let weekNums = [];
    dates.map((date, index) => {
      weekNums.push(moment(date).week());
    });
    if (_.uniq(_.map(weekNums)).length > 1) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
}
export function getEventType(type) {
  let status = eventStatus;
  return status.filter((x) => x.label === type)[0].value;
}
export function getEventTypeByValue(value) {
  let status = eventStatus;
  return status.filter((x) => x.value == value)[0].label;
}
export function checkIsTaskValid(tasks) {
  let tasksClone = [...tasks];
  for (let i = 0; i < tasks.length; i++) {
    let task = tasks[i];
    let isEmptyTask = Object.keys(task).map((x) => {
      if ((x === "title" || x === "description") && task[x] === "") {
        return true;
      }
      if ((x === "taskTags" || x === "taskFiles") && task[x].length === 0) {
        return true;
      }
      if (x === "taskSessions") {
        let isSessionHaveValue = false;
        for (var index = 0; index < task[x].length; index++) {
          let session = task[x];
          let isessionEmpty = session.filter(
            (value) =>
              value.startTime !== "" ||
              value.endTime !== "" ||
              value.maxVolunteerCount !== ""
          );
          if (isessionEmpty.length > 0) {
            isSessionHaveValue = true;
            break;
          }
        }
        if (!isSessionHaveValue) {
          return true;
        }
      }
    });
    if (!isEmptyTask.includes(undefined)) {
      tasksClone.splice(i, 1);
    }
  }
  return tasksClone;
}
export function getProfileFromStorage() {
  let porfile = localStorage.getItem("profile");
  if (porfile) {
    return JSON.parse(porfile);
  }
  return null;
}

export function getMinDateFromArray(dates) {
  let eventDate = dates.map((d) => moment(d)),
    minDate = moment.min(eventDate);
  return moment(minDate).toDate();
}
export function timeDifferenceInSeconds(startDate, endDate) {
  return moment(endDate).diff(moment(startDate), "seconds");
}
export function validateEmail(email) {
  var re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
