import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import assetsImage from "../assets/assets";
import InfiniteScroll from "react-infinite-scroll-component";
import { EVENT_LIST_LENGTH, eventStatus } from "../constant";
import { getEventType } from "../Helpers/Utils";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";

const EventList = ({
  events,
  fetchMoreData,
  isFetchMore,
  type,
  isLoading,
  hasMore,
  setHasMore,
}) => {
  const fetchData = () => {
    fetchMoreData(getEventType(type));
  };
  const navigate = useNavigate();
  const getEventDetails = (eventId) => {
    if (type !== "Past") {
      navigate(`/event/detail/${eventId}`, {});
    }
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : events.length === 0 ? (
        "No Record Found."
      ) : (
        <div className="row">
          <InfiniteScroll
            dataLength={events.length}
            next={fetchData}
            hasMore={events.length < EVENT_LIST_LENGTH ? false : true}
            loader={!hasMore ? "" : <Loader />}
          >
            <div className="col-md-12 ">
              <div>
                <table className="table table-hover">
                  <thead>
                    <tr className="pb-3">
                      <th>Name</th>
                      <th>Schedule</th>
                      <th>Next event date</th>
                      <th>Tasks</th>
                      <th>Volunteers needed</th>
                      <th>Volunteers approved</th>
                    </tr>
                  </thead>
                  <tbody>
                    {events.map((event, index) => (
                      <tr
                        onClick={() => getEventDetails(event.eventId)}
                        key={index}
                        style={{
                          cursor: "pointer",
                        }}
                        className={event.selected ? "selected" : ""}
                      >
                        <td data-label="Name">
                          <button className="event-list-btn text-decoration-none text-align-start">
                            {event.title}
                          </button>
                        </td>
                        <td data-label="Schedule">
                          {event.recurringType ? (
                            <>{event.recurringType}</>
                          ) : (
                            "- - - "
                          )}
                        </td>
                        <td data-label="Next event date">
                          {event.eventDate.length > 0 &&
                            event.eventDate.map((date, index) => {
                              return (
                                <span key={index} className="ms-2">
                                  {event.eventDate.length - 1 !== index
                                    ? moment(date).format("dddd, MMM DD") + ","
                                    : moment(date).format("dddd, MMM DD")}
                                </span>
                              );
                            })}
                        </td>
                        <td data-label="Tasks ">{event.taskCount}</td>
                        <td data-label="Volunteers needed">
                          {event.volunteerCount}
                        </td>
                        <td data-label="Volunteers approved">
                          {event.volunteerApproved}
                        </td>
                        <td></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </InfiniteScroll>
        </div>
      )}
    </>
  );
};
export default EventList;
