import React, { useState, useEffect } from "react";
import applicants from "../Data/Applicants.json";
import { useParams } from "react-router-dom";
import Loader from "./Loader";

import ApplicantList from "./ApplicantList";
import moment from "moment";

let Tasks = [
  { value: "Attend bar", label: "Attend bar" },
  { value: "Cashier", label: "Cashier" },
  { value: "Raise funds", label: "Raise funds" },
];
let Schdules = [
  { value: "8am - 12pm", label: "8am - 12pm" },
  { value: "9am - 10pm", label: "9am - 10pm" },
  { value: "7am - 12pm", label: "7am - 12pm" },
];

const Applicants = ({
  volunteers,
  selectedEvent,
  setSelectedEvent,
  eventApplications,
  setEventApplications,
  fetchMoreData,
  eventId,
  eventSelectedDate,
  getEventApplications,
  isFilterLoader,
  setFilterLoader,
}) => {
  const [selectedTask, setSelectedTasks] = useState(null);
  const [selectedSchedule, setSelectedSchdules] = useState(null);
  const removeSelectedEvent = (event) => {
    let selectedEventClone = [...selectedEvent];
    let eventApplicationsClone = { ...eventApplications };
    let findEvent = eventApplicationsClone.events.find(
      (x) => x.id === event.id
    );
    if (findEvent) {
      findEvent.isSelected = false;
      eventApplicationsClone.events[findEvent] = findEvent;
    }
    setEventApplications(eventApplicationsClone);
    let findIndex = selectedEventClone.findIndex((e) => e.id === event.id);
    if (findIndex > -1) {
      selectedEventClone.splice(findIndex, 1);
      setSelectedEvent(selectedEventClone);
    }
    getEventApplications();
  };
  const filterRecruitedVolunteers = (recruited) => {
    if (recruited.length > 0) {
      let findData = [];
      recruited.map((r) => {
        let recuritedDate = "";
        if (!eventSelectedDate) {
          recuritedDate = eventApplications?.events[0]?.eventDate;
        } else {
          recuritedDate = eventSelectedDate;
        }

        if (r.dates.length === 0) {
          findData.push(r);
        } else {
          let isDateMatched = r.dates.some(
            (d) => d === moment(recuritedDate).format("YYYY-MM-DD")
          );
          if (isDateMatched) {
            findData.push(r);
          }
        }
      });
      return findData;
    }
    return [];
  };
  const filterApplicationVolunteers = (recruited) => {
    if (recruited.length > 0) {
      let findData = [];
      recruited.map((r) => {
        let recuritedDate = "";
        if (!eventSelectedDate) {
          recuritedDate = eventApplications?.events[0]?.eventDate;
        } else {
          recuritedDate = eventSelectedDate;
        }

        if (r.dates.length === 0) {
          findData.push(r);
        } else {
          let isDateMatched = r.dates.some(
            (d) => d === moment(recuritedDate).format("YYYY-MM-DD")
          );
          if (isDateMatched) {
            findData.push(r);
          }
        }
      });
      return findData;
    }
    return [];
  };

  return (
    <div className="container applicants">
      <div className="py-3 d-flex flex-wrap align-items-center">
        <span className="page-Title mt-3">Volunteers</span>

        {selectedEvent?.length > 0
          ? selectedEvent?.map((filterDetail, e) => (
              <div className="mt-3 " style={{ width: "fit-content" }}>
                <span key={e} className="filter-data ms-md-4 ">
                  {filterDetail?.title}
                  <span className="px-2">
                    {moment(filterDetail?.start).format("hh:mm A")} -
                    {moment(filterDetail?.end).format("hh:mm A")}
                  </span>
                  <span
                    className="px-2 decoration-none cursor-pointer"
                    onClick={() => removeSelectedEvent(filterDetail)}
                  >
                    X
                  </span>
                </span>
              </div>
            ))
          : ""}
      </div>

      <nav>
        <div
          className="nav events-detail-nav nav-pills border-bottom border-s-0"
          id="nav-tab"
          role="tablist"
        >
          <div className="nav-item pe-4">
            <button
              className="nav-link text-dark bg-white px-0 active"
              id="nav-recruited-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-recruited"
              type="button"
              role="tab"
              aria-controls="nav-recruited"
              aria-selected="true"
            >
              Recruited
            </button>
          </div>
          <div className="nav-item pe-4">
            <button
              className="nav-link text-dark bg-white px-0"
              id="nav-applications-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-applications"
              type="button"
              role="tab"
              aria-controls="nav-applications"
              aria-selected="false"
            >
              Applications
            </button>
          </div>
        </div>
      </nav>
      <div className="tab-content" id="nav-tabContent">
        <div
          className="tab-pane fade show active"
          id="nav-recruited"
          role="tabpanel"
          aria-labelledby="nav-recruited-tab"
        >
          {isFilterLoader ? (
            <div className="py-3">
              <Loader />
            </div>
          ) : (
            <ApplicantList
              fetchMoreData={fetchMoreData}
              Tasks={Tasks}
              applicants={filterRecruitedVolunteers(volunteers?.recruited)}
              volunteersRecruited={volunteers}
              Schdules={Schdules}
              setSelectedSchdules={setSelectedSchdules}
              setSelectedTasks={setSelectedTasks}
              selectedTask={selectedTask}
              selectedSchedule={selectedSchedule}
              eventApplications={eventApplications}
              eventId={eventId}
              setEventApplications={setEventApplications}
              type={"recruited"}
              eventSelectedDate={eventSelectedDate}
              getEventApplications={getEventApplications}
            />
          )}
        </div>
        <div
          className="tab-pane fade"
          id="nav-applications"
          role="tabpanel"
          aria-labelledby="nav-applications-tab"
        >
          {isFilterLoader ? (
            <div className="py-3">
              <Loader />
            </div>
          ) : (
            <ApplicantList
              fetchMoreData={fetchMoreData}
              Tasks={Tasks}
              applicants={filterApplicationVolunteers(volunteers?.applications)}
              volunteers={volunteers}
              Schdules={Schdules}
              setSelectedSchdules={setSelectedSchdules}
              setSelectedTasks={setSelectedTasks}
              selectedTask={selectedTask}
              selectedSchedule={selectedSchedule}
              eventApplications={eventApplications}
              eventId={eventId}
              setEventApplications={setEventApplications}
              type={"applications"}
              eventSelectedDate={eventSelectedDate}
              getEventApplications={getEventApplications}
            />
          )}
        </div>
        {/* <div
          className="tab-pane fade"
          id="nav-contact"
          role="tabpanel"
          aria-labelledby="nav-contact-tab"
        >
          <ApplicantList
            Tasks={Tasks}
            applicants={applicants}
            Schdules={Schdules}
            setSelectedSchdules={setSelectedSchdules}
            setSelectedTasks={setSelectedTasks}
          />
        </div> */}
      </div>
    </div>
  );
};

export default Applicants;
