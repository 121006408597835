import moment from "moment";
import { setAppMessage } from "../Actions/appActions";
import { getProfileFromStorage } from "../Helpers/Utils";
import { v4 as uuidv4 } from "uuid";

import {
  localPayloadMapToServer,
  serverPayloadToLocalPayload,
} from "./dto/event";
import { getToken } from "../Helpers/customHooks";
import { method } from "lodash";

export function postEvent(event, callback) {
  let formData = localPayloadMapToServer(event);
  console.log(event, "post event detail");
  let token = getToken();
  const requestOptions = {
    method: "POST",
    headers: {
      authorization: `Bearer ${token}`,
    },
    body: formData,
  };
  try {
    return (dispatch, getState) => {
      fetch(process.env.REACT_APP_API_URL + "Event/Create", requestOptions)
        .then((response) => response.json())
        .then((response) => {
          if (response.success) {
            dispatch(
              setAppMessage({
                success: true,
                component: "createEvent",
                type: "success",
                message: "Event has been created successfully!",
              })
            );
            callback(response);
            // window.location.href = "/";
          } else {
            dispatch(
              setAppMessage({
                success: false,
                component: "createEvent",
                type: "error",
                message: response?.error?.message,
              })
            );
            callback(response);
          }
        })
        .catch(() => {
          dispatch(
            setAppMessage({
              success: false,
              component: "createEvent",
              type: "error",
              message: "Internal server error",
            })
          );
          callback(false);
        });
    };
  } catch (ex) {}
}

export function updateEvent(event, callback) {
  let formData = localPayloadMapToServer(event);
  let token = getToken();

  console.log(event, "event details");
  const requestOptions = {
    method: "PATCH",
    headers: {
      authorization: `Bearer ${token}`,
    },
    body: formData,
  };
  try {
    return (dispatch, getState) => {
      fetch(process.env.REACT_APP_API_URL + "Event/Update", requestOptions)
        .then((response) => response.json())
        .then((response) => {
          if (response.success) {
            dispatch(
              setAppMessage({
                success: true,
                component: "updateEvent",
                type: "success",
                message: "Event has been updated successfully!",
              })
            );
            callback(response);
            // window.location.href = "/events";
          } else {
            dispatch(
              setAppMessage({
                success: false,
                component: "updateEvent",
                type: "error",
                message: response?.error?.message,
              })
            );
            callback(response);
          }
        })
        .catch(() => {
          dispatch(
            setAppMessage({
              success: false,
              component: "updateEvent",
              type: "error",
              message: "Internal server error",
            })
          );
          callback(false);
        });
    };
  } catch (ex) {}
}

export function postEvents(event) {
  let formData = new FormData();

  formData.append("eventNames", event.eventNames);
  formData.append("contribution", event.contribution);
  formData.append("volunteerRequired", event.volunteerRequired);
  let token = getToken();

  const requestOptions = {
    method: "POST",
    headers: {
      authorization: `Bearer ${token}`,
    },
    body: formData,
  };
  try {
    fetch(process.env.REACT_APP_API_URL + "Events/Create", requestOptions)
      .then((response) => response.json())
      .then((response) => {
        // window.location.href = "/events";
      });
  } catch (ex) {}
}

export function getEventDetailById(id) {
  console.log(id);
  try {
    let token = getToken();

    return new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_API_URL + `Event/GetEventById?Id= ${id}`, {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((response) => {
          console.log("response", response);
          let event = serverPayloadToLocalPayload(response.result);
          debugger;
          resolve(event);
        });
    });
  } catch (ex) {}
}

// export function UpdateEvent(event) {
//   let formData = new FormData();
//   formData.append("EventId", event.id);
//   formData.append("eventName", event.eventName);
//   formData.append("contribution", event.contribution);
//   formData.append("eventDate", event.eventDate);
//   formData.append("startTime", event.startTime);
//   formData.append("endTime", event.endTime);
//   formData.append("increments", event.increments);
//   formData.append("location", event.location);
//   formData.append("Image", event.eventImg);
//   formData.append(
//     "EventTags",
//     typeof event.eventTags == "string"
//       ? event.eventTags
//       : JSON.stringify(event.eventTags)
//   );
//   formData.append("volunteersRequired", event.volunteersRequired);
//   formData.append("IsPublished", event.isPublished);

//   const updateOptions = {
//     method: "PATCH",
//     body: formData,
//   };
//   try {
//     fetch(process.env.REACT_APP_API_URL + "Event/Update", updateOptions)
//       .then((response) => response.json())
//       .then((response) => {
//         window.location.href = "/events";
//       });
//   } catch (ex) {}
// }

export function getEventApplication(id, page = 1) {
  // try {
  //   return new Promise((resolve, reject) => {
  //     fetch(
  //       process.env.REACT_APP_API_URL +
  //         `Event/Applications?EventId=${id}&page=${page}`
  //     )
  //       .then((response) => response.json())
  //       .then((response) => {
  //         resolve(response);
  //       });
  //   });
  // } catch (ex) {}
}

export function unpublishEvent(eventId) {
  let profile = getProfileFromStorage();
  let token = getToken();

  try {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: "POST",
        body: JSON.stringify({
          eventId: [eventId],
          organizationId: profile.userId,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: `Bearer ${token}`,
        },
      };
      fetch(process.env.REACT_APP_API_URL + "Event/Unpublish", requestOptions)
        .then((response) => response.json())
        .then((response) => {
          resolve(response);
        });
    });
  } catch (ex) {}
}

export function appreveApplication(applicantCode, eventID) {
  try {
    let token = getToken();

    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: "POST",
        body: JSON.stringify({
          applicantCode: [applicantCode],
          eventID: eventID,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: `Bearer ${token}`,
        },
      };
      fetch(process.env.REACT_APP_API_URL + "Event/Approve", requestOptions)
        .then((response) => response.json())
        .then((response) => {
          resolve(response);
        });
    });
  } catch (ex) {}
}

export function getTags() {
  try {
    let token = getToken();

    return new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_API_URL + "Interest/GetInterest", {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            data.result.items.length > 0 &&
              data.result.items.map((tag) => {
                tag["isActive"] = false;
              });
            resolve(data.result.items);
          }
        });
    });
  } catch (ex) {}
}
export function getWebEvents(organizationId = 1, status, page, callback) {
  let token = getToken();

  return (dispatch, getState) => {
    let profile = getProfileFromStorage();
    try {
      fetch(
        `${process.env.REACT_APP_API_URL}Event/GetWebEvents?organizationId=${profile.userId}&&eventStatus=${status}&&pageNo=${page}`,
        {
          method: "GET",
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.success) {
            callback(response);
          } else {
            dispatch(
              setAppMessage({
                success: false,
                component: "event",
                type: "error",
                message: response?.error?.message,
              })
            );
            callback(response);
          }
        });
    } catch (ex) {
      dispatch(
        setAppMessage({
          success: false,
          component: "event",
          type: "error",
          message: "Internal server error",
        })
      );
      callback(false);
    }
  };
}
export function getAccountDetailById(userId) {
  try {
    let profile = getProfileFromStorage();
    return new Promise((resolve, reject) => {
      let token = getToken();
      fetch(
        process.env.REACT_APP_API_URL +
          `User/GetOrganizationById?id=${profile.userId}`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          resolve(response);
        });
    });
  } catch (ex) {}
}
export function getUpcomingEvent() {
  let profile = getProfileFromStorage();
  try {
    let token = getToken();

    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: "POST",

        body: JSON.stringify({
          id: profile.userId,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: `Bearer ${token}`,
        },
      };
      fetch(process.env.REACT_APP_API_URL + "Event/Upcoming", requestOptions)
        .then((response) => response.json())
        .then((response) => {
          resolve(response);
        });
    });
  } catch (ex) {}
}

export function getMediaGallery() {
  let profile = getProfileFromStorage();
  try {
    let token = getToken();

    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: `Bearer ${token}`,
        },
      };
      fetch(
        process.env.REACT_APP_API_URL +
          `MediaGallery/GetMediaGallery?OrganizationId=${profile.userId}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((response) => {
          resolve(response);
        });
    });
  } catch (error) {
    console.log(error);
  }
}

export function deleteGalleryImage(id) {
  let profile = getProfileFromStorage();
  let token = getToken();

  try {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: "POST",
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
      fetch(
        process.env.REACT_APP_API_URL +
          `MediaGallery/Delete?MediaId=${id}&&OrganizationId=${profile.userId}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((response) => {
          resolve(response);
        });
    });
  } catch (error) {
    console.log(error);
  }
}

export function postMediaImage(images) {
  let profile = getProfileFromStorage();
  let formData = new FormData();
  formData.append("MediaFiles", images);
  formData.append("OrganizationId", profile.userId);
  formData.append("IsDefault", false);
  try {
    let token = getToken();

    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: "POST",
        headers: {
          authorization: `Bearer ${token}`,
        },
        body: formData,
      };
      fetch(
        process.env.REACT_APP_API_URL + `mediaGallery/create`,
        requestOptions
      )
        .then((response) => response.json())
        .then((response) => {
          resolve(response);
        });
    });
  } catch (error) {}
}

export function getEventApplicationsById(id, pageNumber, taskIds, sessionIds) {
  try {
    let token = getToken();

    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: "POST",
        body: JSON.stringify({
          EventId: id,
          page: pageNumber,
          TaskId: taskIds,
          SessionId: sessionIds,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: `Bearer ${token}`,
        },
      };
      fetch(
        process.env.REACT_APP_API_URL + `Event/EventApplication`,
        requestOptions
      )
        .then((response) => response.json())
        .then((response) => {
          let data = response.result;
          let events = [];
          let resources = [];

          if (data.eventTasks && data.eventTasks.length > 0) {
            let tasks = data.eventTasks;
            for (let i = 0; i < tasks.length; i++) {
              let dates =
                data.eventDate.length === 0
                  ? [moment().format("YYYY-MM-DD")]
                  : data.eventDate;
              data.eventDate = dates;
              let eventDate = dates.map((d, index) => moment(d)),
                minDate = moment.min(eventDate);
              for (let x = 0; x < tasks[i].sessions.length; x++) {
                let eventObj = {};
                let session = tasks[i].sessions[x];
                let unique_id = uuidv4();
                let sTime = session.startTime
                  ? moment(minDate).format("YYYY-MM-DD") +
                    " " +
                    session.startTime +
                    ":00"
                  : moment(minDate).format("YYYY-MM-DD") + " " + "00:01:00";
                let eTime = session.endTime
                  ? moment(minDate).format("YYYY-MM-DD") +
                    " " +
                    session.endTime +
                    ":00"
                  : moment(minDate).format("YYYY-MM-DD") + " " + "23:59:00";

                eventObj["id"] = unique_id + x;
                eventObj["taskId"] = tasks[i].taskId;
                eventObj["title"] =
                  tasks[i].title +
                  " " +
                  session.applyVolunteerCount +
                  "/" +
                  session.maxVolunteerCount;
                eventObj["eventDate"] = moment(minDate).format("YYYY-MM-DD");
                eventObj["applyVolunteerCount"] = session.applyVolunteerCount;
                eventObj["start"] = moment(
                  sTime,
                  "YYYY-MM-DD hh:mm:SS "
                ).format("YYYY-MM-DD hh:mm:SS A");
                eventObj["end"] = moment(eTime, "YYYY-MM-DD hh:mm:SS ").format(
                  "YYYY-MM-DD hh:mm:SS A"
                );
                eventObj["maxVolunteerCount"] = session.maxVolunteerCount;
                eventObj["taskSessionId"] = session.taskSessionId;
                eventObj["resourceId"] = `r${tasks[i].taskId}`;
                eventObj["bgColor"] =
                  session.applyVolunteerCount === 0
                    ? "#FFE5E3"
                    : session.applyVolunteerCount < session.maxVolunteerCount &&
                      session.applyVolunteerCount > 0
                    ? "#FFEDCB"
                    : "#03A61D";
                eventObj["resizable"] = false;
                eventObj["movable"] = false;
                eventObj["isSelected"] = false;
                events.push(eventObj);
                resources.push({
                  id: `r${tasks[i].taskId}`,
                  name: `Resource${tasks[i].taskId}`,
                });
              }
            }
          }
          data["events"] = events;
          data["resources"] = resources;
          resolve(data);
        });
    });
  } catch (ex) {}
}
export function ApproveApplication(data) {
  let profile = getProfileFromStorage();

  try {
    let token = getToken();

    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: `Bearer ${token}`,
        },
      };
      fetch(process.env.REACT_APP_API_URL + "Event/Approve", requestOptions)
        .then((response) => response.json())
        .then((response) => {
          resolve(response);
        });
    });
  } catch (ex) {}
}
