import moment from "moment";
import { dateDifference, timeDifferenceInSeconds } from "../../Helpers/Utils";

export const validate = (data, isPublished) => {
  const errors = {};
  errors.isTabOneError = false;
  errors.isSecondTabError = false;

  if (!data.eventDetail.title) {
    errors.title = "Please enter the title of your event!";
    errors.isTabOneError = true;
  }
  // debugger;
  if (!isPublished) {
    if (!data.eventDetail.description) {
      errors.description = "Please describe your event !";
      errors.isTabOneError = true;
    }
    if (!data.eventDetail.isRemote && !data.eventDetail.address) {
      errors.address = "Please select address from google suggestion !";
      errors.isTabOneError = true;
    } else if (!data.eventDetail.isRemote && !data.eventDetail.state) {
      errors.address =
        "State is missing in your address. Please select complete address !";
      errors.isTabOneError = true;
    } else if (!data.eventDetail.isRemote && !data.eventDetail.city) {
      errors.address =
        "City is missing in your address. Please select complete address !";
      errors.isTabOneError = true;
    }

    if (
      !data.eventDetail.isAnytime &&
      data.eventDetail.eventDate.length === 0
    ) {
      errors.dateError = "Please choose at least a date for this Event !";
      errors.isTabOneError = true;
    }
    if (
      !data.eventDetail.isAnytime &&
      data.eventDetail.isRecurring &&
      !data.eventDetail.recurringType
    ) {
      errors.recurringError = "Please Choose the recurring Events";
      errors.isTabOneError = true;
    }
  }
  let taskName = data.eventTasks;
  // debugger;
  if (taskName.length > 0) {
    for (let i = 0; i < taskName.length; i++) {
      let task = taskName[i];
      let isEmptyTask = Object.keys(task).map((x) => {
        if ((x === "title" || x === "description") && task[x] === "") {
          return true;
        }
        if ((x === "taskTags" || x === "taskFiles") && task[x].length === 0) {
          return true;
        }
        if (x === "eventTaskId" && task[x] === "") {
          return true;
        }
        if (x === "taskSessions") {
          let isSessionHaveValue = false;
          for (var index = 0; index < task[x].length; index++) {
            let session = task[x];
            let isessionEmpty = session.filter(
              (value) =>
                value.startTime !== "" ||
                value.endTime !== "" ||
                value.maxVolunteerCount !== ""
            );
            if (isessionEmpty.length > 0) {
              isSessionHaveValue = true;
              break;
            }
          }
          if (!isSessionHaveValue) {
            return true;
          }
        }
      });
      // if (isEmptyTask.includes(undefined)) {
      if (taskName[i].title === "") {
        errors[`title${i}`] = `Please Enter task title.`;
        errors.isSecondTabError = true;
      }
      if (!data.eventDetail.isAnytime) {
        for (let j = 0; j < taskName[i].taskSessions.length; j++) {
          const taskSession = taskName[i].taskSessions[j];
          let timeDifference = timeDifferenceInSeconds(
            moment(taskSession.startTime + ":00", "H:mm:ss"),
            moment(taskSession.endTime + ":00", "H:mm:ss")
          );
          if (!taskSession.startTime) {
            errors.isSecondTabError = true;
            errors[`taskSession${i}-${j}`] = `Please enter start time.`;
          } else if (!taskSession.endTime) {
            errors.isSecondTabError = true;
            errors[`taskSession${i}-${j}`] = `Please enter end time.`;
          } else if (timeDifference <= 0) {
            errors.isSecondTabError = true;
            errors[
              `taskSession${i}-${j}`
            ] = `End time must be greater than start time.`;
          }
        }
      }
      // }
    }
  } else {
    errors.isSecondTabError = true;
    errors[`title0`] = `Please Enter task title`;
  }
  if (!errors.isTabOneError) {
    delete errors.isTabOneError;
  }
  if (!errors.isSecondTabError) {
    delete errors.isSecondTabError;
  }
  return errors;
};
