// import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../css/EventDetail.css";
import { useParams } from "react-router-dom";
import assetsImage from "../../assets/assets";
import {
  appreveApplication,
  getEventApplication,
  getEventDetailById,
  unpublishEvent,
  getEventApplicationsById,
} from "../../Services/EventService";
import NewEventTabs from "./NewEventTabs";
import Loader from "../../Components/Loader";
import Applicants from "../../Components/Applicants";
import TaskCalendar from "../../Components/TaskCalendar";
const ViewEvent = () => {
  const [list, setList] = useState([]);
  const [masterChecked, setMasterChecked] = useState(false);
  const [selectedList, setSelectedList] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState([]);
  console.log(selectedEvent, "selected event");
  const [eventDetail, setEventDetail] = useState({});
  const [eventApplications, setEventApplications] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const [isFilterLoader, setFilterLoader] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [eventSelectedDate, setEventSelectedDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useLocation();
  let { id } = useParams();
  const submitUnpublishEvent = async (organizationId) => {
    setIsLoading(true);
    let res = await unpublishEvent(parseInt(id), parseInt(organizationId));
    if (res.length > 0 && res[0].code) {
    } else {
      window.location.href = "/";
    }
    setIsLoading(false);
  };
  const approveData = async (applicantCode) => {
    let res = await appreveApplication(applicantCode, parseInt(id));
  };
  useEffect(() => {
    // getEventDetail();
    setIsLoader(true);
    getEventApplications(pageNumber);
  }, []);

  const getEventDetail = async () => {
    setIsLoader(true);
    let eventDetail = await getEventDetailById(id);
    setEventDetail(eventDetail);
    setIsLoader(false);
  };

  const getEventApplications = async (pageNumber, taskIds, sessionIds) => {
    let eventApplications = await getEventApplicationsById(
      id,
      pageNumber,
      taskIds,
      sessionIds
    );
    setEventApplications(eventApplications);
    setIsLoader(false);
    setFilterLoader(false);
  };
  // console.log(selectedEvent[0].taskId, "select id");
  const fetchMoreData = () => {
    setPageNumber(pageNumber + 1);
    getEventApplications(pageNumber + 1);
  };
  // Select/ UnSelect Table rows
  const onMasterCheck = (e) => {
    let tempList = list;
    // Check/ UnCheck All Items
    tempList.map((applicant) => (applicant.selected = e.target.checked));
    //Update State
    setMasterChecked(e.target.checked);
    setList(tempList);
    setSelectedList(
      list.filter((e) => !e.approvalState).map((e) => e.applicantCode)
    );
  };
  // Update List Item's state and Master Checkbox State
  const onItemCheck = (e, item) => {
    let tempList = list;
    tempList.map((applicant) => {
      if (applicant.applicantCode === item.applicantCode) {
        applicant.selected = e.target.checked;
      }
      return applicant;
    });
    //To Control Master Checkbox State
    const totalItems = list.length;
    const totalCheckedItems = tempList.filter((e) => e.selected).length;
    // Update State
    setMasterChecked(totalItems === totalCheckedItems);
    setList(tempList);
    let selectedEventIds = [];

    if (selectedList.length > 0) {
      selectedEventIds = [...selectedList];
      let findIndex = selectedEventIds.findIndex(
        (x) => x === item.applicantCode
      );
      if (findIndex > -1) {
        selectedEventIds.splice(findIndex, 1);
      } else {
        selectedEventIds.push(item.applicantCode);
      }
    } else {
      selectedEventIds.push(item.applicantCode);
    }
    setSelectedList(selectedEventIds);
  };
  useEffect(() => {
    setFilterLoader(true);
    let selectedEventClone = [...selectedEvent];
    debugger;
    if (selectedEvent.length > 0) {
      let taskIds = selectedEvent.map((event) => event.taskId);
      let sessionIds = selectedEvent.map((event) => event.taskSessionId);
      getEventApplications(pageNumber, taskIds, sessionIds);
    }
  }, [selectedEvent]);
  const handleCalendarEvent = (event) => {};
  return (
    <>
      {isLoader ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <div className="page-head">
              <h3 className="page-Title">{eventApplications?.title} </h3>
              <div className="location">{eventApplications?.address}</div>
              <div className="recurring">
                {eventApplications?.recurringType}
              </div>
            </div>
            <div className="head-buttons">
              <Link className="btn-event" to={`/event/edit/${id}`}>
                Edit
              </Link>
              {eventApplications?.isPublish && (
                <Link
                  className="btn-event"
                  onClick={() => submitUnpublishEvent(id)}
                  disabled={isLoading}
                >
                  Unpublish
                  {isLoading && (
                    <div
                      className="spinner-border text-danger ms-2"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </Link>
              )}
            </div>
          </div>

          <div>
            {Object.keys(eventApplications).length > 0 && (
              <>
                <TaskCalendar
                  eventApplications={eventApplications}
                  selectedEvent={selectedEvent}
                  setSelectedEvent={setSelectedEvent}
                  setEventApplications={setEventApplications}
                  eventSelectedDate={eventSelectedDate}
                  setEventSelectedDate={setEventSelectedDate}
                />
                <Applicants
                  fetchMoreData={fetchMoreData}
                  getEventApplications={getEventApplications}
                  volunteers={eventApplications?.volunteers}
                  selectedEvent={selectedEvent}
                  setSelectedEvent={setSelectedEvent}
                  eventApplications={eventApplications}
                  setEventApplications={setEventApplications}
                  eventId={id}
                  eventSelectedDate={eventSelectedDate}
                  setEventSelectedDate={setEventSelectedDate}
                  isFilterLoader={isFilterLoader}
                  setFilterLoader={setFilterLoader}
                />
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};
export default ViewEvent;
