import React, { useEffect, useRef, useState } from "react";
import {
  StandaloneSearchBox,
  LoadScript,
  Autocomplete,
} from "@react-google-maps/api";
import { useLocation } from "react-router-dom";
const AutoAdress = ({
  formData,
  setFormData,
  formErrors,
  showErrors,
  setShowErrors,
}) => {
  const [autocomplete, setAutocomplete] = useState(null);
  const inputRef = useRef();
  const location = useLocation();
  const onLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };
  const handlePlaceChanged = () => {
    debugger;
    if (autocomplete !== null) {
      console.log(autocomplete.getPlace());
      let data = { ...formData };
      let place = autocomplete.getPlace();
      data.eventDetail.address = place.formatted_address;
      data.eventDetail.latitude = place.geometry.location.lat();
      data.eventDetail.longitude = place.geometry.location.lng();

      data.eventDetail.state = place.address_components.find((component) =>
        component.types.includes("administrative_area_level_1")
      ).long_name;
      data.eventDetail.city = place.address_components.find((component) =>
        component.types.includes("locality")
      ).long_name;
      data.eventDetail.postalCode = place.address_components.find((component) =>
        component.types.includes("postal_code")
      ).long_name;
      setShowErrors(false);
      setFormData(data);
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };
  const options = {
    componentRestrictions: { country: "us" },
  };
  return (
    <div>
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        libraries={["places"]}
      >
        <Autocomplete
          onLoad={onLoad}
          onPlaceChanged={handlePlaceChanged}
          placeholder="Enter Location"
          options={options}
        >
          <input
            type="text"
            className={
              "form-control" +
              ((showErrors && formErrors.address) ||
              formErrors.city ||
              formErrors.state ||
              formErrors.postalCode
                ? " validation-alert"
                : "")
            }
            placeholder="Enter Location"
            value={formData.eventDetail.address}
            // disabled={
            //   formData?.id && formData.eventDetail.isPublish ? true : false
            // }
            onChange={(e) => {
              let data = { ...formData };
              data.eventDetail.address = e.target.value;
              data.eventDetail.city = "";
              data.eventDetail.state = "";
              data.eventDetail.latitude = "";
              data.eventDetail.longitude = "";
              setFormData(data);
            }}
          />
        </Autocomplete>
      </LoadScript>
      {showErrors && formErrors.address ? (
        <div className="danger">{formErrors.address}</div>
      ) : null}
      {showErrors && formErrors.city ? (
        <div className="danger">{formErrors.city}</div>
      ) : null}
      {showErrors && formErrors.state ? (
        <div className="danger">{formErrors.state}</div>
      ) : null}
      {showErrors && formErrors.postalCode ? (
        <div className="danger">{formErrors.postalCode}</div>
      ) : null}
    </div>
  );
};

export default AutoAdress;
