// import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../css/EventDetail.css";
import { useParams } from "react-router-dom";
import assetsImage from "../../assets/assets";
import {
  appreveApplication,
  getEventApplication,
  getEventDetailById,
  unpublishEvent,
} from "../../Services/EventService";
import NewEventTabs from "./NewEventTabs";
import Loader from "../../Components/Loader";
import Applicants from "../../Components/Applicants";
import TaskCalendar from "../../Components/TaskCalendar";
const EditEvent = () => {
  const [list, setList] = useState([]);
  const [masterChecked, setMasterChecked] = useState(false);
  const [selectedList, setSelectedList] = useState([]);
  const [eventDetail, setEventDetail] = useState({});
  const { state } = useLocation();
  let { id } = useParams();
  const submitUnpublishEvent = async (organizationId) => {
    let res = await unpublishEvent(parseInt(id), parseInt(organizationId));
    if (res.length > 0 && res[0].code) {
    } else {
      window.location.href = "/";
    }
  };
  const approveData = async (applicantCode) => {
    let res = await appreveApplication(applicantCode, parseInt(id));
  };
  useEffect(() => {
    getEventDetail();
    getEventApplciation();
  }, []);
  const getEventApplciation = async () => {
    let application = await getEventApplication(id);
    setList(application || []);
  };
  const getEventDetail = async () => {
    let eventDet = await getEventDetailById(id);
    setEventDetail(eventDet);
  };

  // Select/ UnSelect Table rows
  const onMasterCheck = (e) => {
    let tempList = list;
    // Check/ UnCheck All Items
    tempList.map((applicant) => (applicant.selected = e.target.checked));
    //Update State
    setMasterChecked(e.target.checked);
    setList(tempList);
    setSelectedList(
      list.filter((e) => !e.approvalState).map((e) => e.applicantCode)
    );
  };
  // Update List Item's state and Master Checkbox State
  const onItemCheck = (e, item) => {
    let tempList = list;
    tempList.map((applicant) => {
      if (applicant.applicantCode === item.applicantCode) {
        applicant.selected = e.target.checked;
      }
      return applicant;
    });
    //To Control Master Checkbox State
    const totalItems = list.length;
    const totalCheckedItems = tempList.filter((e) => e.selected).length;
    // Update State
    setMasterChecked(totalItems === totalCheckedItems);
    setList(tempList);
    let selectedEventIds = [];

    if (selectedList.length > 0) {
      selectedEventIds = [...selectedList];
      let findIndex = selectedEventIds.findIndex(
        (x) => x === item.applicantCode
      );
      if (findIndex > -1) {
        selectedEventIds.splice(findIndex, 1);
      } else {
        selectedEventIds.push(item.applicantCode);
      }
    } else {
      selectedEventIds.push(item.applicantCode);
    }
    setSelectedList(selectedEventIds);
  };

  return (
    <>
      <div>
        {Object.keys(eventDetail).length > 0 ? (
          <NewEventTabs eventDetail={eventDetail} />
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};
export default EditEvent;
