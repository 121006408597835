import React, { useState, useEffect } from "react";
import EventTagsList from "../Data/EventTagsList.json";
import Accordion from "react-bootstrap/Accordion";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { getTags } from "../Services/EventService";
const EventTags = ({ setFormData, formData, callback, type, index, task }) => {
  const [tags, setTags] = useState([]);
  useEffect(() => {
    getTagsFromServer();
  }, []);
  const getTagsFromServer = async () => {
    let data = await getTags();
    if (formData.id) {
      let seletectedTags =
        type === "event" ? formData.eventDetail.eventTag : task.taskTags;
      seletectedTags.map((result) => {
        let findIndex = data.findIndex((x) => x.id === result);
        if (findIndex > -1) {
          data[findIndex].isActive = true;
        }
      });
      if (type === "event") {
        let eventTags = data.filter((x) => x.isActive);
        formData.eventDetail.eventTag = eventTags;
        setFormData(formData);
      } else {
        let taskTags = data.filter((x) => x.isActive);
        formData.eventTasks[index].taskTags = taskTags;
        setFormData(formData);
      }
    }
    setTags(data);
  };
  // useEffect(() => {
  //   inittags();
  // }, [formData]);
  // const [tags, setTags] = useState(EventTagsList.Tags);
  const inittags = () => {
    if (formData.id) {
      let seletectedTags =
        type === "event" ? formData.eventDetail.eventTag : task.taskTags;
      seletectedTags.map((result) => {
        let findIndex = tags.findIndex((x) => x.id === result.id);
        if (findIndex > -1) {
          tags[findIndex].isActive = true;
        }
      });
    }
    setTags(tags);
    // return EventTags.Tags;
  };
  const tagButton = (tag) => {
    const newState =
      tags.length > 0 &&
      tags.map((obj) => {
        if (obj.id === tag.id) {
          return { id: tag.id, title: tag.title, isActive: !tag.isActive };
        }
        return obj;
      });
    setTags(newState);
    let activeTags = newState.filter((x) => x.isActive);
    callback(activeTags);

    // setFormData({
    //   ...formData,
    //   eventDetail: { ...formData.eventDetail, tags: newState },
    // });
  };
  const validationSchema = Yup.object({
    contribution: Yup.string().required("Please enter the Event contribution"),
    volunteersRequired: Yup.string().required("Please Enter Volunteer Numbers"),
  });

  return (
    <div>
      <div className='className="event-tags-accordion mt-3'>
        <Accordion
          defaultActiveKey={
            type === "event"
              ? formData.eventDetail.eventTag.length > 0
                ? "event-0"
                : ""
              : task.taskTags.length > 0
              ? "task-0"
              : ""
          }
        >
          <Accordion.Item
            className="p-0 border-0"
            eventKey={`${type === "event" ? "event-0" : "task-0"}`}
          >
            <Accordion.Header>
              {type === "event"
                ? formData?.eventDetail?.eventTag.length > 0
                  ? formData?.eventDetail?.eventTag.length + " selected"
                  : "Please select"
                : task?.taskTags.length > 0
                ? task?.taskTags.length + " selected"
                : "Please select"}
            </Accordion.Header>
            <Accordion.Body className="mt-1">
              <div>
                <Formik
                  // initialValues={formData?.eventDetail}
                  validationSchema={validationSchema}
                >
                  {(formik) => {
                    const { setFieldValue, values, handleChange, errors } =
                      formik;

                    // newEvent(values);
                    return (
                      <div className="addFormNew">
                        <div className="tag-compontent">
                          {tags.map((tagData, i) => (
                            <button
                              onClick={() => tagButton(tagData)}
                              type="button"
                              key={tagData.id}
                              className={
                                "tag-Button" +
                                (tagData.isActive ? " active" : "")
                              }
                            >
                              {tagData.title}
                            </button>
                          ))}
                        </div>
                      </div>
                    );
                  }}
                </Formik>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default EventTags;
