import React from "react";
import Images from "../assets/assets.js";
const UploadPdf = ({ formData, setFormData, index }) => {
  const uploadFile = (e) => {
    let data = { ...formData };
    data.eventTasks[index].taskFiles.push(e.target.files[0]);
    setFormData(data);
    e.target.value = "";
  };
  return (
    <div>
      {formData?.eventTasks[index].taskFiles.length > 0 ? (
        <div className="position-relative">
          <label className="py-2">
            {formData?.eventTasks[index].taskFiles[0].name}
          </label>
          <span
            onClick={(e) => {
              let data = { ...formData };
              data.eventTasks[index].taskFiles = [];
              setFormData(data);
            }}
            className="text-decoration-none remove-file-btn ps-3"
          >
            Remove
          </span>
          <label htmlFor="taskFiles" className="form-control">
            <span className="pe-2">
              <img
                src={Images.PdfIcons}
                width={22}
                className="img-fluid"
                alt=""
              />
            </span>
            <a
              className="text-decoration-none text-dark"
              target="_blank"
              href={formData?.eventTasks[index]?.taskFiles[0]?.url}
              download
            >
              {formData?.eventTasks[index]?.taskFiles[0].name}
            </a>
          </label>
        </div>
      ) : (
        <div>
          <label className="py-2">Orientation / Training</label>
          <label
            htmlFor={`taskFiles-${index}`}
            className="form-control text-center cursor-pointer"
          >
            <span className="upload-text">Upload a pdf</span>
          </label>
        </div>
      )}
      <input
        type="file"
        accept="application/pdf"
        className="d-none w-100"
        onChange={(e) => uploadFile(e)}
        id={`taskFiles-${index}`}
        name={`taskFiles-${index}`}
        placeholder="Upload a pdf"
      ></input>
    </div>
  );
};

export default UploadPdf;
