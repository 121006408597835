import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "../../css/NewEventTab.css";
import Media from "../../Components/Media";
import NewEvent from "./NewEvent";
import Task from "../../Components/TaskComponent";
import moment from "moment";
import { postEvent, updateEvent } from "../../Services/EventService";
import _ from "lodash";
import { validate } from "./validate";
import { useDispatch } from "react-redux";

const NewEventTabs = ({ eventDetail, eventID }) => {
  const { id } = useParams();

  const taskSession = {
    startTime: "",
    endTime: "",
    maxVolunteerCount: "",
    taskSessionId: "",
  };
  const eventTaskObj = {
    title: "",
    description: "",
    taskTags: [],
    eventTaskId: "",
    taskSessions: [
      {
        startTime: "",
        endTime: "",
        maxVolunteerCount: "",
        taskSessionId: "",
      },
    ],
    taskFiles: [],
  };
  const [formData, setFormData] = useState(
    eventDetail || {
      id: "",
      eventDetail: {
        title: "",
        description: "",
        address: "",
        city: "",
        state: "",
        postalCode: "",
        longitude: "",
        latitude: "",
        isRemote: false,
        eventDate: [],
        isRecurring: false,
        recurringType: "",
        isAnytime: false,
        isPublish: "",
        eventTag: [],
        eventImage: [],
      },
      eventTasks: [eventTaskObj],
      rawEventTasks: [],
    }
  );
  const [formErrors, setFormErrors] = useState({});
  const [isDraftEvent, setIsDraftEvent] = useState(false);
  const [isPublishEvent, setIsPublishEvent] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [isChecked, setIsChecked] = useState(
    eventDetail?.increments ? true : false
  );
  const [activeTab, setActiveTab] = useState(1);
  const [tasks, setTasks] = useState(1);
  const tabs = [
    {
      id: 1,
      name: "pills-detail",
      liName: "pills-detail-tab",
      isActive: false,
    },
    {
      id: 2,
      name: "pills-volunteers",
      liName: "pills-volunteers-tab",
      isActive: false,
    },
    { id: 3, name: "pills-media", liName: "pills-media-tab", isActive: false },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // console.log("Event data", formData);
  const [isSubmit, setIsSubmit] = useState(false);

  const changeFormCalenderBorder = () => {
    let formCalender = document.getElementsByClassName("rmdp-top-class")[0];
    if (formData.eventDetail.eventDate.length == 0) {
      if (formData.eventDetail.eventDate.length == 0) {
        formCalender?.classList.add("validation-alert");
      } else {
        formCalender?.classList.remove("validation-alert");
      }
    }
  };

  useEffect(() => {
    let formCalender = document.getElementsByClassName("rmdp-top-class")[0];
    formCalender?.classList.remove("validation-alert");
  }, [formData]);

  useEffect(() => {
    if (showErrors && formErrors?.dateError) {
      changeFormCalenderBorder();
    }
  }, [formErrors, showErrors]);

  const manageActiveTab = (values) => {
    let eventFormErrors = validate(formData);
    console.log(eventFormErrors);
    changeFormCalenderBorder();

    let isValidForm = true;
    if (Object.keys(eventFormErrors).length > 0) {
      if (activeTab === 1) {
        delete eventFormErrors?.isSecondTabError;
      }
      if (
        (activeTab === 1 && eventFormErrors?.isTabOneError) ||
        (activeTab === 2 && eventFormErrors?.isSecondTabError)
      ) {
        isValidForm = false;
        setFormErrors(eventFormErrors);
        setIsSubmit(true);
        setShowErrors(true);
      }
    }

    if (isValidForm) {
      let currentTab = tabs.find((x) => x.id === activeTab);
      var currentLiElement = document.getElementById(currentTab.liName);
      var currentElement = document.getElementById(currentTab.name);
      let nextActiveTab = activeTab === 3 ? activeTab - 1 : activeTab + 1;
      let findTab = tabs.find((x) => x.id === nextActiveTab);
      var element = document.getElementById(findTab.name);
      var liElement = document.getElementById(findTab.liName);
      currentElement.classList.remove("show", "active");
      currentLiElement.classList.remove("active");
      element.classList.add("show", "active");
      liElement.classList.add("active");
      setActiveTab(nextActiveTab);
    }
  };
  const handleSumbit = (isPublished) => {
    formData.eventDetail["isPublish"] = isPublished;
    console.log("validate(formData) formErrors");
    console.log("formErrors", formErrors);

    let eventFormErrors = validate(formData, isPublished);
    let errorsObj = {};
    if (isPublished == false) {
      if (eventFormErrors.title) {
        errorsObj = { title: eventFormErrors.title };
      }
      setFormErrors(errorsObj);
    } else {
      setFormErrors(eventFormErrors);
    }

    setIsSubmit(true);
    setShowErrors(true);
    if (
      Object.keys(isPublished == false ? errorsObj : eventFormErrors).length ===
      0
    ) {
      if (isPublished) {
        setIsPublishEvent(true);
      } else {
        setIsDraftEvent(true);
      }
      if (formData.id) {
        dispatch(
          updateEvent(formData, (response) => {
            setIsPublishEvent(false);
            setIsDraftEvent(false);
            if (response.success) {
              navigate("/");
            } else {
            }
          })
        );
      } else {
        dispatch(
          postEvent(formData, (response) => {
            setIsPublishEvent(false);
            setIsDraftEvent(false);
            if (response.success) {
              navigate("/");
            } else {
            }
          })
        );
      }
    }

    // formData.eventDate = moment(formData.eventDate).format("yyyy-MM-DD");
    // // Validation

    // if (formData.id) {
    //   formData.startTime = moment("12/12/1998 " + formData.startTime).format(
    //     "H:mm"
    //   );
    //   formData.endTime = moment("12/12/1998 " + formData.endTime).format(
    //     "H:mm"
    //   );

    //   UpdateEvent(formData);
    // } else {
    //   formData.startTime = moment("12/12/1998 " + formData.startTime).format(
    //     "H:mm"
    //   );
    //   formData.endTime = moment("12/12/1998 " + formData.endTime).format(
    //     "H:mm"
    //   );

    // }
  };
  // const ValidDate = (date) => {
  //   return date instanceof Date && !isNaN(date);
  // };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
    }
  }, [formErrors]);
  const handleSaveAsDraft = () => {
    setShowErrors(true);
    setFormErrors(validate(formData));
  };
  const validateDate = formData.eventDetail.eventDate.every(
    (date) => date instanceof Date && !isNaN(date)
  );
  const validateTaskTitle = formData.eventTasks.every(
    (value) => value.title.length > 0
  );
  const minuteConverterIntoNumbers = (time) => {
    const [h, m] = time.split(":");
    const value = +h + +m / 60;
    return value.toFixed(2);
  };
  const timeValues = [...formData.eventTasks]
    .map((e) => e.taskSessions)
    .flat(3);

  const startTimeArray = timeValues.map((e) =>
    minuteConverterIntoNumbers(e.startTime)
  );
  const endTimeArray = timeValues.map((e) =>
    minuteConverterIntoNumbers(e.endTime)
  );
  const checkEndTimeGreater = (startTime, endTime) => {
    return (
      Array.isArray(startTime) &&
      Array.isArray(endTime) &&
      startTime.every((val, index) => val < endTime[index])
    );
  };
  const endTimeGreateValidity = checkEndTimeGreater(
    startTimeArray,
    endTimeArray
  );
  const taskError = (form) => {
    const taskError = [];

    return taskError;
  };

  const newEvent = (values) => {
    // console.log(values);
  };
  // console.log(formData);

  return (
    <div className="new-event-tabs">
      <h3 className="page-Title">{id ? "Edit" : "New"} Event</h3>
      <ul
        className="nav events-detail-nav nav-pills border-bottom border-s-0"
        id="pills-tab"
        role="tablist"
      >
        <li className="nav-item pe-4" role="presentation">
          <button
            className="nav-link active text-dark bg-white px-0"
            id="pills-detail-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-detail"
            type="button"
            role="tab"
            aria-controls="pills-detail"
            aria-selected="true"
            onClick={() => setActiveTab(1)}
          >
            Event details
          </button>
        </li>
        <li className="nav-item pe-4" role="presentation">
          <button
            className="nav-link text-dark bg-white px-0"
            id="pills-volunteers-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-volunteers"
            type="button"
            role="tab"
            aria-controls="pills-volunteers"
            aria-selected="false"
            onClick={() => {
              let eventFormErrors = validate(formData);
              let isValidForm = true;
              if (
                Object.keys(eventFormErrors).length > 0 &&
                eventFormErrors?.isTabOneError
              ) {
                isValidForm = false;
                setFormErrors(eventFormErrors);
                setIsSubmit(true);
                setShowErrors(true);
              }

              if (isValidForm) {
                setActiveTab(2);
              } else {
                setTimeout(() => {
                  var previousElement = document.getElementById("pills-detail");
                  var previousLiElement =
                    document.getElementById("pills-detail-tab");
                  previousElement.classList.add("show", "active");
                  previousLiElement.classList.add("active");
                  var element = document.getElementById("pills-volunteers");
                  var elementLI = document.getElementById(
                    "pills-volunteers-tab"
                  );
                  element.classList.remove("show");
                  element.classList.remove("active");
                  elementLI.classList.remove("active");
                  setActiveTab(1);
                }, 300);
              }
            }}
          >
            Volunteering needs
          </button>
        </li>
        <li className="nav-item pe-4" role="presentation">
          <button
            className="nav-link text-dark bg-white px-0"
            id="pills-media-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-media"
            type="button"
            role="tab"
            aria-controls="pills-media"
            aria-selected="false"
            onClick={() => {
              debugger;
              let eventFormErrors = validate(formData);
              let isValidForm = true;
              let tabName = "";
              if (
                Object.keys(eventFormErrors).length > 0 &&
                eventFormErrors?.isTabOneError
              ) {
                isValidForm = false;
                setFormErrors(eventFormErrors);
                setIsSubmit(true);
                setShowErrors(true);
                setActiveTab(1);
                tabName = "firstTab";
              } else if (
                Object.keys(eventFormErrors).length > 0 &&
                eventFormErrors?.isSecondTabError
              ) {
                isValidForm = false;
                setFormErrors(eventFormErrors);
                setIsSubmit(true);
                setShowErrors(true);
                setActiveTab(2);
                tabName = "secondTab";
              }
              if (isValidForm) {
                setActiveTab(3);
              } else {
                setTimeout(() => {
                  if (tabName === "firstTab") {
                    var previousElement =
                      document.getElementById("pills-detail");
                    var previousLiElement =
                      document.getElementById("pills-detail-tab");
                    previousElement.classList.add("show", "active");
                    previousLiElement.classList.add("active");
                    var element = document.getElementById("pills-media");
                    var elementLI = document.getElementById("pills-media-tab");
                    element.classList.remove("show");
                    element.classList.remove("active");
                    elementLI.classList.remove("active");
                    setActiveTab(1);
                  }
                  if (tabName === "secondTab") {
                    var previousElement = "";
                    var previousLiElement = "";
                    // if (activeTab === 1) {
                    //   previousElement = document.getElementById("pills-detail");
                    //   previousLiElement =
                    //     document.getElementById("pills-detail-tab");
                    // }
                    // if (activeTab === 2) {
                    previousElement =
                      document.getElementById("pills-volunteers");
                    previousLiElement = document.getElementById(
                      "pills-volunteers-tab"
                    );
                    // }

                    previousElement.classList.add("show", "active");
                    previousLiElement.classList.add("active");
                    var element = document.getElementById("pills-media");
                    var elementLI = document.getElementById("pills-media-tab");
                    element.classList.remove("show");
                    element.classList.remove("active");
                    elementLI.classList.remove("active");
                    setActiveTab(2);
                  }

                  // setActiveTab(1);
                }, 300);
                if (isValidForm) {
                  setActiveTab(3);
                }
              }
            }}
          >
            Media
          </button>
        </li>
        <div className="ms-auto bg-white">
          <button
            type="button"
            // onClick={() => handleSumbit(false)}
            onClick={() => handleSumbit(false)}
            className="btn save-draft"
            disabled={
              formData?.id && formData.eventDetail.isPublish ? true : false
            }
          >
            Save as draft
            {isDraftEvent && (
              <div className="spinner-border text-red ms-2" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
          </button>
          {activeTab === 3 ? (
            <Link
              onClick={() => handleSumbit(true)}
              className="text-decoration-none submit-btn ps-4"
            >
              Publish
              {isPublishEvent && (
                <div className="spinner-border text-white ms-2" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </Link>
          ) : (
            <Link
              className="text-decoration-none next-btn ps-4"
              onClick={manageActiveTab}
            >
              Next
            </Link>
          )}
        </div>
      </ul>
      <div className="tab-content" id="pills-tabContent">
        <div
          className="event-detail-table tab-pane fade show active py-5"
          id="pills-detail"
          role="tabpanel"
          aria-labelledby="pills-detail-tab"
        >
          <div className="row">
            <NewEvent
              showErrors={showErrors}
              setShowErrors={setShowErrors}
              id={id}
              newEvent={newEvent}
              manageActiveTab={manageActiveTab}
              formData={formData}
              setFormData={setFormData}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
            />
          </div>
        </div>
        <div
          className="tab-pane fade py-5 event-detail-table"
          id="pills-volunteers"
          role="tabpanel"
          aria-labelledby="pills-volunteers-tab"
        >
          {formData.eventTasks.map((task, index) => {
            return (
              <Task
                showErrors={showErrors}
                setShowErrors={setShowErrors}
                key={index}
                task={task}
                index={index}
                setTasks={setTasks}
                formData={formData}
                setFormData={setFormData}
                eventTaskObj={eventTaskObj}
                taskSession={taskSession}
                formErrors={formErrors}
              />
            );
          })}
          <div className="new-task py-5 border border-start-0 border-end-0 border-top-0">
            <Link
              onClick={() => {
                if (formData.eventTasks.length < 20) {
                  let data = { ...formData };
                  data.eventTasks.push(Object.assign({}, eventTaskObj));
                  setFormData(data);
                }
              }}
              className="new-task-btn"
            >
              + Add a new task
            </Link>
          </div>
        </div>
        <div
          className="tab-pane fade py-5 event-detail-table"
          id="pills-media"
          role="tabpanel"
          aria-labelledby="pills-volunteers-tab"
        >
          <Media formData={formData} setFormData={setFormData} />
        </div>
      </div>
      {/* <div className="button-NewEvent mt-5">
        {activeTab === 2 ? (
          <button
            type="button"
            onClick={() => handleSumbit(true)}
            className="btn next-button"
          >
            Publish
          </button>
        ) : (
          <button
            type="button"
            className="btn submit-button"
            onClick={manageActiveTab}
          >
            Next
          </button>
        )}
        
      </div> */}
    </div>
  );
};

export default NewEventTabs;
