import _ from "lodash";
import moment from "moment";
import { checkIsTaskValid, getProfileFromStorage } from "../../Helpers/Utils";

const serverPaylod = {
  title: "",
  description: "",
  address: "",
  city: "",
  state: "",
  postalCode: null,
  longitude: null,
  latitude: null,
  isRemote: false,
  eventDate: [],
  isRecurring: false,
  recurringType: "",
  isAnytime: false,
  OrganizationId: null,
  isPublish: false,
  eventTag: [],
  eventImage: [],
  eventTasks: [
    {
      title: "",
      description: "",
      taskTags: [],
      taskSessions: [
        {
          startTime: "",
          endTime: "",
          maxVolunteerCount: null,
        },
      ],
      taskFiles: [],
    },
  ],
};
const taskSession = {
  startTime: "",
  endTime: "",
  maxVolunteerCount: "",
  taskSessionId: "",
};
let localPayload = {
  id: "",
  eventDetail: {
    title: "",
    description: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
    longitude: "",
    latitude: "",
    isRemote: false,
    eventDate: [],
    isRecurring: false,
    recurringType: "",
    isAnytime: false,
    isPublish: "",
    eventTag: [],
    eventImage: [],
  },
  eventTasks: [
    {
      title: "",
      description: "",
      taskTags: [],
      taskSessions: [taskSession],
      taskFiles: [],
    },
  ],
};
export function localPayloadMapToServer(payload) {
  console.log(payload);
  let formData = new FormData();
  let profile = getProfileFromStorage();
  if (payload.eventDetail) {
    let event = payload.eventDetail;
    let eventTags = _.map(event.eventTag, "id");
    formData.append("OrganizationId", profile.userId);

    formData.append("title", event.title);
    if (event.description) {
      formData.append("description", event.description);
    }
    if (!event.isRemote) {
      // if (event.isRemote) {
      //   formData.append("address", "");
      //   formData.append("city", "");
      //   formData.append("state", "");
      // }
      if (event.address) {
        formData.append("address", event.address);
      }
      if (event.city) {
        formData.append("city", event.city);
      }
      if (event.state) {
        formData.append("state", event.state);
      }
      if (event.postalCode) {
        formData.append("postalCode", event.isRemote ? "" : event.postalCode);
      }
      if (event.longitude) {
        formData.append("longitude", event.isRemote ? "" : event.longitude);
      }
      if (event.latitude) {
        formData.append("latitude", event.isRemote ? "" : event.latitude);
      }
    }
    if (!event.isAnytime && event.isRecurring) {
      formData.append(
        "isRecurring",
        event.isAnytime ? false : event.isRecurring
      );
      if (event.recurringType) {
        formData.append("recurringType", event.recurringType);
      }
    }
    if (event.isRemote) {
      formData.append("isRemote", event.isRemote);
    }
    if (event.isAnytime) {
      formData.append("isAnytime", event.isAnytime);
    }

    formData.append("isPublish", event.isPublish);
    if (eventTags.length > 0) {
      eventTags.map((tag) => {
        formData.append("eventTag", tag);
      });
    }

    if (event.eventDate.length > 0 && !event.isAnytime) {
      event.eventDate.map((date) => {
        formData.append("eventDate", moment(date).format("YYYY-MM-DD"));
      });
    }
    if (event.eventImage && event.eventImage.length > 0) {
      event.eventImage.map((img) => {
        formData.append("eventImage", img);
      });
    }
  }
  let tasks = checkIsTaskValid(payload.eventTasks);
  if (tasks.length > 0) {
    tasks.map((task, index) => {
      if (task.title) {
        formData.append(`eventTasks[${index}][title]`, task.title);
      }
      if (task.eventTaskId) {
        formData.append(`eventTasks[${index}][Id]`, task.eventTaskId);
      }
      if (task.description) {
        formData.append(`eventTasks[${index}][description]`, task.description);
      }
      if (task.taskTags.length > 0) {
        let taskTags = _.map(task.taskTags, "id");
        taskTags.map((tag) => {
          formData.append(`eventTasks[${index}][taskTags]`, tag);
        });
      }
      if (task.taskFiles && task.taskFiles.length > 0) {
        task.taskFiles.map((img) => {
          formData.append(`eventTasks[${index}].taskFiles`, img);
        });
      }
      task.taskSessions.map((session, sessionIndex) => {
        if (session.startTime && !payload.eventDetail.isAnytime) {
          formData.append(
            `eventTasks[${index}][taskSessions][${sessionIndex}][startTime]`,
            session.startTime
          );
        }
        if (session.endTime && !payload.eventDetail.isAnytime) {
          formData.append(
            `eventTasks[${index}][taskSessions][${sessionIndex}][endTime]`,
            session.endTime
          );
        }
        if (session.taskSessionId) {
          formData.append(
            `eventTasks[${index}][taskSessions][${sessionIndex}][Id]`,
            session.taskSessionId
          );
        }
        if (session.maxVolunteerCount) {
          formData.append(
            `eventTasks[${index}][taskSessions][${sessionIndex}][maxVolunteerCount]`,
            session.maxVolunteerCount
          );
        }
      });
    });
  }
  if (payload.id) {
    formData.append("Id", payload.id);
  }
  return formData;
}
export function serverPayloadToLocalPayload(payload) {
  let formPayload = {
    id: "",
    eventDetail: {
      title: "",
      description: "",
      address: "",
      city: "",
      state: "",
      postalCode: "",
      longitude: "",
      latitude: "",
      isRemote: false,
      eventDate: [],
      isRecurring: false,
      recurringType: "",
      isAnytime: false,
      isPublish: "",
      eventTag: [],
      eventImage: [],
    },
    eventTasks: [
      {
        title: "",
        description: "",
        taskTags: [],
        eventTaskId: "",
        taskSessions: [taskSession],
        taskFiles: [],
      },
    ],
    rawEventTasks: [],
    rawEventDetail: {},
  };
  if (payload) {
    formPayload.rawEventDetail = payload;
    formPayload.eventDetail.title = payload?.title;
    formPayload.eventDetail.description = payload?.description;
    formPayload.eventDetail.address = payload?.address;
    formPayload.eventDetail.city = payload?.city;
    formPayload.eventDetail.state = payload?.state;
    formPayload.eventDetail.postalCode = payload?.postalCode;
    formPayload.eventDetail.longitude = payload?.longitude;
    formPayload.eventDetail.latitude = payload?.latitude;
    formPayload.eventDetail.isRemote = payload?.isRemote;
    if (payload?.eventDate && payload?.eventDate.length > 0) {
      payload?.eventDate.map((date) => {
        formPayload.eventDetail.eventDate.push(moment(date).toDate());
      });
    }
    formPayload.eventDetail.isRecurring = payload?.isRecurring;
    formPayload.eventDetail.recurringType = payload?.recurringType;
    formPayload.eventDetail.isAnytime = payload?.isAnytime;
    formPayload.eventDetail.isPublish = payload?.isPublish;
    formPayload.eventDetail.eventTag = payload?.eventTag;
    formPayload.eventDetail.eventImage = payload?.eventImage;
    formPayload.id = payload?.eventId;
    if (payload?.eventTasks.length > 0) {
      // let eventTasks = [];
      // payload?.eventTasks.map((task) => {
      //   let taskObj = {
      //     title: task.title,
      //     description: task.description,
      //     taskTags: task.taskTags,
      //     eventTaskId: task.eventTaskId,
      //     taskFiles: task.taskFiles,
      //   };
      //   let sesssions = [];

      //   if (formPayload.eventDetail.isAnytime) {
      //     task.taskSessions.map((session) => {
      //       sesssions.push({
      //         endTime: session.endTime,
      //         maxVolunteerCount: session.maxVolunteerCount,
      //         startTime: session.startTime,
      //         taskSessionId: "",
      //       });
      //     });
      //   } else {
      //     sesssions = task.taskSessions;
      //   }
      //   taskObj.taskSessions = sesssions;
      //   eventTasks.push(taskObj);
      // });
      // formPayload.eventTasks = eventTasks;
      formPayload.eventTasks = [...payload?.eventTasks];
      formPayload.rawEventTasks = [...payload?.eventTasks];
    }
  }
  return formPayload;
}
