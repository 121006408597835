import React, { useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import * as Yup from "yup";
import { Form, Formik } from "formik";
const TaskShift = ({
  setShifts,
  shift,
  index,
  formData,
  setFormData,
  shiftIndex,
  formErrors,
  showErrors,
  setShowErrors,
}) => {
  const validationSchema = Yup.object({
    eventName: Yup.string().required("Pelase Enter the Event Name "),
    location: Yup.string().required("Pelase Enter the Location"),
  });
  console.log("formData===>", formData);
  const isDateEditable = (taskIndex, shiftIndex) => {
    console.log("isDateEditable");
    if (formData?.id) {
      if (
        typeof formData.rawEventTasks[taskIndex].taskSessions[shiftIndex] ===
        "undefined"
      ) {
        return true;
      }
      return false;
    }
    return true;
  };
  return (
    <div className="container">
      <Formik
        initialValues={formData?.eventDetail}
        validationSchema={validationSchema}
      >
        {(formik) => {
          const { setFieldValue, values, handleChange, errors } = formik;
          // newEvent(values);
          return (
            <Form className="addFormNew">
              <div className="row mt-2">
                {formData?.eventDetail?.isAnytime === true ? (
                  ""
                ) : (
                  <div className="col-sm-12 col-md-5">
                    <div className="form-group">
                      {shiftIndex !== 0 ? (
                        ""
                      ) : (
                        <label className="py-2">Schedule</label>
                      )}

                      <div
                        className={
                          "row rounded" +
                          (showErrors &&
                          formErrors[`taskSession${index}-${shiftIndex}`]
                            ? " validation-alert"
                            : "")
                        }
                        style={{
                          backgroundColor: "#f4f4f4",
                          // border: showErrors && "1px solid #ff1b11",
                        }}
                      >
                        <div className="col pe-0">
                          <DatePicker
                            disableDayPicker
                            format="hh:mm A"
                            className="form-control"
                            placeholder="Start time"
                            readOnly={
                              formData.eventTasks[index].taskSessions[
                                shiftIndex
                              ].taskSessionId !== "" &&
                              !formData?.rawEventDetail?.isAnytime &&
                              formData.eventDetail.isPublish
                                ? true
                                : false
                            }
                            value={
                              formData.eventTasks[index].taskSessions[
                                shiftIndex
                              ].startTime
                                ? moment(
                                    formData.eventTasks[index].taskSessions[
                                      shiftIndex
                                    ].startTime,
                                    "hh:mm"
                                  ).toDate()
                                : null
                            }
                            onChange={(e) => {
                              const hour = e.hour;
                              const minute = e.minute;
                              const time = `${hour}:${minute}`;
                              console.log("time====>", e);
                              // values.startTime = time;
                              let data = { ...formData };
                              data.eventTasks[index].taskSessions[
                                shiftIndex
                              ].startTime = time;
                              setFormData(data);
                              setShowErrors(false);
                            }}
                            plugins={[<TimePicker hideSeconds />]}
                          />
                          {/* <input
                type="time"
                // value={
                //   formData["startTime"]
                //     ? moment("12/12/1998 " + formData.startTime).format(
                //         "hh:mm a"
                //       )
                //     : null
                // }
                format="hh:mm a"
                className="form-control"
                placeholder="Start time"
                id="startTime"
                name="startTime"
                onChange={(value) =>
                  setFormData((p) => ({
                    ...p,
                    startTime: moment(value).format("hh:mm a"),
                  }))
                }
              /> */}
                        </div>
                        <div className="col-1 px-0">
                          <div className="pt-3"> - </div>
                        </div>
                        <div className="col px-0">
                          <DatePicker
                            disableDayPicker
                            placeholder="End time"
                            format="hh:mm A"
                            className="form-control"
                            name="endTime"
                            readOnly={
                              formData.eventTasks[index].taskSessions[
                                shiftIndex
                              ].taskSessionId !== "" &&
                              !formData?.rawEventDetail?.isAnytime &&
                              formData.eventDetail.isPublish
                                ? true
                                : false
                            }
                            value={
                              formData.eventTasks[index].taskSessions[
                                shiftIndex
                              ].endTime
                                ? moment(
                                    formData.eventTasks[index].taskSessions[
                                      shiftIndex
                                    ].endTime,
                                    "hh:mm"
                                  ).toDate()
                                : null
                            }
                            onChange={(e) => {
                              const hour = e.hour;
                              const minute = e.minute;
                              const time = `${hour}:${minute}`;
                              let data = { ...formData };
                              data.eventTasks[index].taskSessions[
                                shiftIndex
                              ].endTime = time;
                              setFormData(data);
                              setShowErrors(false);
                            }}
                            plugins={[<TimePicker hideSeconds />]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-sm-11 col-md-5">
                  <div className="form-group">
                    {shiftIndex !== 0 ? (
                      ""
                    ) : (
                      <label className="py-2"># of volunteers needed</label>
                    )}

                    <input
                      type="number"
                      min="0"
                      className="form-control"
                      placeholder="Enter a number"
                      id="volunteersRequired"
                      value={
                        formData.eventTasks[index].taskSessions[shiftIndex]
                          .maxVolunteerCount
                      }
                      onChange={(e) => {
                        let data = { ...formData };
                        data.eventTasks[index].taskSessions[
                          shiftIndex
                        ].maxVolunteerCount = e.target.value;
                        setFormData(data);
                      }}
                    />
                  </div>
                </div>
                {formData.eventTasks[index].taskSessions[shiftIndex]
                  .taskSessionId === "" ? (
                  <div className="col-1" key={shiftIndex}>
                    {shiftIndex !== 0 && (
                      <div className="bg-white pt-2">
                        <Link
                          className="text-decoration-none new-shift-btn"
                          onClick={() => {
                            let data = { ...formData };
                            data.eventTasks[index].taskSessions.splice(
                              shiftIndex,
                              1
                            );
                            setFormData(data);
                          }}
                        >
                          x
                        </Link>
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}
                {showErrors &&
                formErrors[`taskSession${index}-${shiftIndex}`] ? (
                  <div className="danger">
                    {formErrors[`taskSession${index}-${shiftIndex}`]}
                  </div>
                ) : null}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default TaskShift;
