import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import { Navbar } from "react-bootstrap";
import { Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setProfileAction, setTokenAction } from "../Actions/profileAction";
import assets from "../assets/assets.js";
const VeroNavbar = () => {
  const profile = useSelector((state) => state.profileReducer.profile);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (localStorage.getItem("profile")) {
      let data = JSON.parse(localStorage.getItem("profile"));
      dispatch(setProfileAction(data));
      let token = localStorage.getItem("token");
      dispatch(setTokenAction(token));
    }
  }, []);
  return (
    <>
      {["md"].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          className="mb-3 border-bottom header-style"
        >
          <Container>
            <Navbar.Brand className="text-start logo-div">
              <Link to="/">
                <img alt="vero" src={assets.VeroLogo} className="logo" />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <img alt="vero" src={assets.VeroLogo} className="logo" />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1">
                  {profile && Object.keys(profile).length > 0 && (
                    <>
                      <Link to="/" className="nav-link active">
                        Events
                      </Link>
                      <Link to="/account" className="nav-link">
                        Account
                      </Link>
                    </>
                  )}
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
};

export default VeroNavbar;
