import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { postEvents } from "../Services/EventService";
import Form from "react-bootstrap/Form";
import moment from "moment";
import TaskShift from "./TaskShift";
import EventTags from "./EventTags";
import UploadPdf from "./UploadPdf";
import _ from "lodash";

const Task = ({
  task,
  index,
  setTasks,
  formData,
  setFormData,
  eventTaskObj,
  taskSession,
  formErrors,

  setFormErrors,
  showErrors,
  setShowErrors,
}) => {
  const [shift, setShifts] = useState(1);
  const setTags = (selectedTags) => {
    let data = { ...formData };
    data.eventTasks[index].taskTags = selectedTags;
    setFormData(data);
  };
  return (
    <>
      <div className="task-comp">
        <div>
          <div
            className="row pb-5 pe-5 border border-start-0 border-end-0 border-top-0"
            key={index}
          >
            {task.eventTaskId === "" ? (
              <>
                {index !== 0 && (
                  <div className="ms-auto text-end new-event-btn bg-white pt-2">
                    <p
                      className="remove-text text-decoration-none"
                      onClick={() => {
                        let data = { ...formData };
                        data.eventTasks.splice(index, 1);
                        setFormData(data);
                      }}
                    >
                      Remove
                    </p>
                  </div>
                )}
              </>
            ) : (
              ""
            )}

            <div className="form-group mt-3">
              <input
                type="text"
                className={
                  "task-name w-100 rounded" +
                  (showErrors && formErrors[`title${index}`]
                    ? " validation-alert"
                    : "")
                }
                name="taskName"
                placeholder="Task name... (ie: Serve Food)"
                id="taskName"
                value={formData.eventTasks[index].title}
                onChange={(e) => {
                  let data = { ...formData };
                  data.eventTasks[index].title = e.target.value;
                  setFormData(data);
                  setShowErrors(false);
                }}
              />
              {showErrors && formErrors[`title${index}`] ? (
                <div className="danger">{formErrors[`title${index}`]}</div>
              ) : null}
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 pe-lg-5">
              <div className="form-group mt-1 position-relative">
                <label className="py-2">Description</label>
                <input
                  className="form-control"
                  name="contribution"
                  placeholder="Describe this task in a few words..."
                  value={formData.eventTasks[index].description}
                  onChange={(e) => {
                    let data = { ...formData };
                    data.eventTasks[index].description = e.target.value;
                    setFormData(data);
                  }}
                />
              </div>
              <div className="form-group mt-3">
                <label htmlFor="eventtags">Task tags</label>
                <EventTags
                  setFormData={setFormData}
                  formData={formData}
                  task={task}
                  callback={setTags}
                  type="task"
                  index={index}
                />
              </div>
              <div className="form-group mt-3">
                <UploadPdf
                  task={task}
                  index={index}
                  setTasks={setTasks}
                  formData={formData}
                  setFormData={setFormData}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 ps-xl-5">
              {formData.eventTasks[index].taskSessions.map(
                (shift, shiftIndex) => {
                  return (
                    <TaskShift
                      key={shiftIndex}
                      shift={shift}
                      shiftIndex={shiftIndex}
                      index={index}
                      setShifts={setShifts}
                      formData={formData}
                      setFormData={setFormData}
                      formErrors={formErrors}
                      showErrors={showErrors}
                      setShowErrors={setShowErrors}
                    />
                  );
                }
              )}

              {formErrors.newValidTime ? (
                <div className="danger">{formErrors.newValidTime}</div>
              ) : null}
              <div className="new-task py-5">
                <Link
                  onClick={() => {
                    // if (formData.eventTasks < 20 && formData.eventTasks > 0) {
                    let data = { ...formData };
                    if (data.eventTasks[index].taskSessions.length == 0) {
                      data.eventTasks[index].taskSessions.push({
                        ...taskSession,
                      });
                    } else {
                      data.eventTasks[index].taskSessions.push({
                        ...taskSession,
                        startTime:
                          data.eventTasks[index].taskSessions[
                            data.eventTasks[index].taskSessions.length - 1
                          ].endTime || "",
                      });
                    }
                    debugger;
                    setFormData(data);
                    // }
                  }}
                  className="new-task-btn"
                >
                  + Add another shift
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Task;
