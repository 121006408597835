import React from "react";
import assets from "../assets/assets";

const Loader = ({ height = "50px" }) => {
  return (
    <div className="text-center">
      <img height={height} src={assets.loader} />
    </div>
  );
};

export default Loader;
