import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link, useNavigate, useParams } from "react-router-dom";
// import {
//   loginService,
//   LoginService,
//   ForgetPassword,
// } from "../Services/LoginServices";
import { useDispatch, useSelector } from "react-redux";
import FormikControl from "../../Validation/FormikControl";
import { ResetPassword } from "../../Services/LoginServices";

function ForgotPassword() {
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isForogtLoading, setIsForgotLoading] = useState(false);
  const [forgetPassword, setForgetPassword] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { hash, email } = useParams();
  const initialValues = {
    password: "",
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .required("Please enter your new password")
      .min(6, "Password is too short - should be 6 chars minimum"),
  });
  const navigateToDashboard = (isSuccess) => {
    setIsLoading(false);
    if (isSuccess) {
      navigate("/", { replace: true });
    }
  };
  const onSubmit = async (values, actions) => {
    setIsLoading(true);
    dispatch(
      ResetPassword(
        {
          emailAddress: email,
          newPassword: values.password,
          resetCode: hash,
        },
        (isSuccess) => navigateToDashboard(isSuccess)
      )
    );
  };
  useEffect(() => {
    if (localStorage.getItem("profile")) {
      navigate("/", { replace: true });
    }
    // if (user.profile && Object.keys(user.profile).length > 0) {
    //   navigate("/", { replace: true });
    // }
  }, []);
  const GetPasswordByEmail = async () => {
    setIsForgotLoading(true);
    dispatch();
    //   ForgetPassword(forgetPassword, (response) => {
    //     setIsForgotLoading(false);
    //     if (response.success) {
    //       setForgetPassword(null);
    //       window.jQuery("#forgetPasswordModal").modal("hide");
    //     }
    //   })
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form className="login-form px-xl-5">
              <div className="row">
                <div className="col-sm-12 col-md-2 col-lg-3"></div>
                <div className="col-sm-12 col-md-8 col-lg-6">
                  <div className="px-xl-5">
                    <h3 className="page-Title">Forgot Password</h3>
                    <div className="form-group mt-3 position-relative">
                      <FormikControl
                        control="input"
                        type="password"
                        label="New Password"
                        name="password"
                        placeholder="Enter new password"
                        className="form-control p-3"
                      />
                    </div>
                    <div className="d-flex justify-content-between">
                      <div>
                        <button
                          className="login-btn mt-3"
                          type="submit"
                          disabled={!formik.isValid && isLoading}
                        >
                          Reset
                          {isLoading && (
                            <div
                              className="spinner-border text-white ms-2"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          )}
                        </button>
                      </div>
                    </div>
                    <div className="mt-4 d-flex justify-content-between">
                      <Link
                        className="sign-btn mt-3 text-decoration-none"
                        to={"/signin"}
                      >
                        Login
                      </Link>{" "}
                      <Link
                        className="sign-btn mt-3 text-decoration-none"
                        to={"/register"}
                      >
                        Create one
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default ForgotPassword;
