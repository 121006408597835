import { APP_Message } from "../constants/appConstant";
const INITIAL_STATE = {
  message: {},
};
export const appReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case APP_Message:
      return { ...state, message: { ...action.payload } };
    default:
      return state;
  }
};
