import React from "react";

import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";

function TimePicker(props) {
  const {
    label,
    name,
    format,
    showTimeSelect,
    showTimeSelectOnly,
    timeIntervals,
    timeCaption,
    ...rest
  } = props;
  return (
    <div>
      <label htmlFor={name}>{label}</label>

      <Field
        name={name}
        format={format}
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={showTimeSelectOnly}
        timeIntervals={timeIntervals}
        timeCaption={timeCaption}
      >
        {({ form, field }) => {
          // console.log(field);
          const { setFieldValue } = form;
          const { value } = field;
          return (
            <input
              id={name}
              {...field}
              {...rest}
              selected={value}
              onChange={(val) => setFieldValue(name, val)}
            />
          );
        }}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default TimePicker;
