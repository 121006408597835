import React from "react";
import { Link } from "react-router-dom";
function Template404() {
  return (
    <div className="template-container text-center">
      <h1 className="text-center text-uppercase title">404 page</h1>
      <div className="button-container mt-4">
        <Link to="/" className=" text-uppercase back-home">
          Back To Home
        </Link>
      </div>
    </div>
  );
}
export default Template404;
