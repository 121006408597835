import applicantimg1 from "./Oval1.png";
import applicantimg2 from "./Oval2.png";
import applicantimg3 from "./Oval3.png";
import applicantimg4 from "./Oval4.png";
import applicantimg5 from "./Oval5.png";
import applicantimg6 from "./Oval6.png";
import applicantimg7 from "./Oval7.png";
import neweventimg from "./card-img.png";
import loader from "./loader.gif";
import Media1 from "./media/media1.png";
import Media2 from "./media/media2.png";
import Media3 from "./media/media3.png";
import Media4 from "./media/media4.png";
import Media5 from "./media/media5.png";
import Media6 from "./media/media6.png";
import Media7 from "./media/media7.png";
import Media8 from "./media/media8.png";
import Media9 from "./media/media9.png";
import Media10 from "./media/media10.png";
import Media11 from "./media/media11.png";
import Plus from "./media/plus.png";
import VeroLogo from "./logo.svg";
import DragEventImages from "./media/dragMedia.png";

import cardImg from "./card-img.png";
import mobilePreviewImg from "./mobile-preview.png";
import MobilePreviewTop from "./mobile-top.png";
import PdfIcons from "./pdf.png";
const assetsImage = {
  Media1,
  Media2,
  Media3,
  Media4,
  Media5,
  Media6,
  Media7,
  Media8,
  Media9,
  Media10,
  Media11,
  DragEventImages,
  Plus,
  neweventimg,
  applicantimg1,
  applicantimg2,
  applicantimg3,
  applicantimg4,
  applicantimg5,
  applicantimg6,
  applicantimg7,
  cardImg,
  loader,
  mobilePreviewImg,
  MobilePreviewTop,
  PdfIcons,
  VeroLogo,
};

export default assetsImage;
