import { setAppMessage } from "../Actions/appActions";
import { setProfileAction, setTokenAction } from "../Actions/profileAction";

export function LoginService(info) {
  const mockData = [
    {
      email: "hamza@gmail.com",
      password: "hamza123",
    },
    {
      email: "hamza@gmail.com",
      password: "hamza1234",
    },
    {
      email: "sajid@gmail.com",
      password: "hamza12345",
    },
  ];

  let user = mockData.find(
    (user) => user.email === info.email && user.password === info.password
  );

  return user;
}

export function registrationService(data, callback) {
  try {
    return (dispatch, getState) => {
      const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      };
      fetch(
        process.env.REACT_APP_API_URL + "Organization/Register",
        requestOptions
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.success) {
            callback(response);
          } else {
            dispatch(
              setAppMessage({
                success: false,
                component: "signup",
                type: "error",
                message: response?.error?.message,
              })
            );
            callback(response);
          }
        })
        .catch(() => {
          dispatch(
            setAppMessage({
              success: false,
              component: "signup",
              type: "error",
              message: "Internal server error",
            })
          );
          callback(false);
        });
    };
  } catch (ex) {
    // dispatch(
    //   setAppMessage({
    //     success: false,
    //     component: "signup",
    //     type: "error",
    //     message: "Internal server error",
    //   })
    // );
    // callback(response);
  }
}

export function loginService(data, callback) {
  return (dispatch, getState) => {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };
    fetch(
      process.env.REACT_APP_BASE_API_URL + "TokenAuth/Authenticate",
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          localStorage.setItem("profile", JSON.stringify(response.result));
          localStorage.setItem("token", response.result.refreshToken);
          dispatch(setProfileAction(response.result));
          dispatch(setTokenAction(response.result.refreshToken));
          callback(true);
        } else {
          dispatch(
            setAppMessage({
              success: false,
              component: "login",
              type: "error",
              message: response?.error?.details || response?.error?.message,
            })
          );
          callback(false);
        }
      })
      .catch(() => {
        dispatch(
          setAppMessage({
            success: false,
            component: "login",
            type: "error",
            message: "Internal server error",
          })
        );
        callback(false);
      });
  };
}
export function ForgetPassword(emailAddress, callback, deviceType) {
  const requestOptions = {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify({
      emailAddress: emailAddress,
      deviceType: "1",
    }),
  };
  try {
    return (dispatch, getState) => {
      fetch(
        process.env.REACT_APP_API_URL + "Account/ForgotPassword",
        requestOptions
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.success) {
            dispatch(
              setAppMessage({
                success: true,
                component: "forgetPass",
                type: "success",
                message:
                  "Password Reset Link has been sent to your email please check.!",
              })
            );
            callback(response);
            // window.location.href = "/events";
          } else {
            dispatch(
              setAppMessage({
                success: false,
                component: "forgetPass",
                type: "error",
                message: response?.error?.message,
              })
            );
            callback(response);
          }
        })
        .catch((error) => {
          dispatch(
            setAppMessage({
              success: false,
              component: "forgetPass",
              type: "error",
              message: "Internal server error",
            })
          );
          callback(false);
        });
    };
  } catch (ex) {}
}
export function ResetPassword(data, callback) {
  const requestOptions = {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify({
      emailAddress: data?.emailAddress,
      resetCode: data?.resetCode,
      newPassword: data?.newPassword,
    }),
  };
  try {
    return (dispatch, getState) => {
      fetch(
        process.env.REACT_APP_API_URL + "Account/ResetPassword",
        requestOptions
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.success) {
            dispatch(
              setAppMessage({
                success: true,
                component: "resetPass",
                type: "success",
                message: "Your password has been reset successfully.!",
              })
            );
            callback(response);
            // window.location.href = "/events";
          } else {
            dispatch(
              setAppMessage({
                success: false,
                component: "resetPass",
                type: "error",
                message: response?.error?.message,
              })
            );
            callback(response);
          }
        })
        .catch((error) => {
          dispatch(
            setAppMessage({
              success: false,
              component: "resetPass",
              type: "error",
              message: "Internal server error",
            })
          );
          callback(false);
        });
    };
  } catch (ex) {}
}
