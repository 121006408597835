import React, { useEffect, useState } from "react";
import Tagyourevent from "../../Components/EventTagsAndImage";
import HourIncrements from "../../Data/HourIncrements.json";
import { getEventDetailById, postEvent } from "../../Services/EventService";
import { Calendar } from "react-multi-date-picker";
import moment from "moment";
import { UpdateEvent } from "../../Services/EventService";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormikControl from "../../Validation/FormikControl";
import FileResult from "../../FileUpload/File";
import EventTags from "../../Components/EventTags";
import UplaodImage from "../../Components/UploadImage";
import {
  checkSelectedExistInSameWeek,
  dateDifference,
  getDayFromDate,
  getMinDateFromArray,
} from "../../Helpers/Utils";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import _ from "lodash";
// import PlaceAutocomplete from "./address";
import axios from "axios";
import AutoAdress from "../../Components/AutoAdress";

import Select from "react-select";
import { is } from "@babel/types";

const NewEvent = ({
  eventDetail,
  manageActiveTab,
  formData,
  setFormData,
  newEvent,
  formErrors,
  setFormErrors,
  showErrors,
  setShowErrors,
}) => {
  // const [address, setAddress] = useState("");
  // const [location, setLocation] = useState({});
  // useEffect(() => {
  //   const geocodeAddress = async (address) => {
  //     const response = await fetch(
  //       `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${address}&key=AIzaSyCTgG9bky31CZBNcTHTrjBvGl4vPdDPwcY`
  //     );
  //     const data = await response.json();

  //     console.log(`Response ${data}`);

  //     // if (data.results.length === 0) {
  //     //   setLocation({});
  //     //   return;
  //     // }
  //     const { lat, lng } = data.results[0].geometry.location;
  //     const postal_code = data.results[0].address_components.find((component) =>
  //       component.types.includes("postal_code")
  //     ).long_name;
  //     const city = data.results[0].address_components.find((component) =>
  //       component.types.includes("locality")
  //     ).long_name;
  //     setLocation({ lat, lng, postal_code, city });
  //   };
  //   if (address) {
  //     geocodeAddress(address);
  //   }
  // }, [address]);

  const initialValues = {
    eventName: formData?.eventName || "",

    location: formData?.location || "",
    eventDate: formData?.eventDate || "",
    startTime: "",
    endTime: "",
    contribution: formData?.contribution || "",
    volunteersRequired: "",
    eventDate: formData?.eventDate || "",
    recuringEvent: formData?.recuringEvent || "",
  };
  const validationSchema = Yup.object({
    eventName: Yup.string().required("Pelase Enter the Event Name "),
    location: Yup.string().required("Pelase Enter the Location"),
    eventDate: Yup.date().required("Pelase Enter the Event Date"),
    startTime: Yup.string().required("Event start Time is required"),
    endTime: Yup.string()
      .required("Please Enter the Event End Time ")
      .test(
        "is-greater",
        "end time should be greater than start time",
        function (value) {
          const { start } = this.parent;
          return moment(value, "HH:mm").isSameOrAfter(moment(start, "HH:mm"));
        }
      ),
    contribution: Yup.string().required("Please enter the Event contribution"),
    volunteersRequired: Yup.string().required("Please Enter Volunteer Numbers"),
  });
  const onSubmit = (values) => {
    // console.log(values);
  };
  const [isDisabled, setIsDisabled] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // const onChange = (dates) > {
  //   const [start, end] = dates;
  //   setStartDate(start);
  //   setEndDate(end);
  // };

  //Code for multiple date

  const [values, setValues] = useState([]);

  useEffect(() => {
    if (values.length > 0) {
      values.map((v) => {});
    }
  }, [values]);

  //code for work anytime handle
  const [WorkAnytime, setHandleWorkAnytime] = useState();
  const handleWorkAnytime = () => {
    setHandleWorkAnytime((current) => !current);
  };
  //code for recurring event handle
  const handleRecurring = () => {
    let data = { ...formData };
    data.eventDetail.isRecurring = !formData.eventDetail.isRecurring;
    setFormData(data);
  };

  //code for remote work handle

  // const convert = (dates, format = "MM/DD/YYYY") => {
  //   // let object = { dates, format };
  //   // console.log("dates",date);
  //   let arr = [];
  //   dates.map((date) => {
  //     arr.push(moment(date).toDate())
  //   })
  //   console.log("arr",arr)
  // }
  const setTags = (values) => {
    setFormData({
      ...formData,
      eventDetail: { ...formData.eventDetail, eventTag: values },
    });
  };

  //For recurring select

  const opt = () => {
    let options = [{ value: "", label: "Please select" }];
    if (formData?.eventDetail?.eventDate.length > 0) {
      if (checkSelectedExistInSameWeek(formData?.eventDetail?.eventDate)) {
        options.push({
          value: `Recurs every week on ${
            formData?.eventDetail?.eventDate.length === 1
              ? moment(formData?.eventDetail?.eventDate[0]).format("ddd")
              : formData?.eventDetail?.eventDate.length === 2
              ? moment(formData?.eventDetail?.eventDate[0]).format("ddd") +
                " and " +
                moment(formData?.eventDetail?.eventDate[1]).format("ddd")
              : formData?.eventDetail?.eventDate.length > 2
              ? formData?.eventDetail?.eventDate.map((d, index) => {
                  if (formData?.eventDetail?.eventDate.length - 1 === index) {
                    return (
                      " and " +
                      moment(formData?.eventDetail?.eventDate[index]).format(
                        "ddd"
                      )
                    );
                  } else {
                    if (index === 0) {
                      return moment(
                        formData?.eventDetail?.eventDate[index]
                      ).format("ddd");
                    } else {
                      return (
                        " " +
                        moment(formData?.eventDetail?.eventDate[index]).format(
                          "ddd"
                        )
                      );
                    }
                  }
                })
              : ""
          }`,
          label: ` Recurs every week on ${
            formData?.eventDetail?.eventDate.length === 1
              ? moment(formData?.eventDetail?.eventDate[0]).format("ddd")
              : formData?.eventDetail?.eventDate.length === 2
              ? moment(formData?.eventDetail?.eventDate[0]).format("ddd") +
                " and " +
                moment(formData?.eventDetail?.eventDate[1]).format("ddd")
              : formData?.eventDetail?.eventDate.length > 2
              ? formData?.eventDetail?.eventDate.map((d, index) => {
                  if (formData?.eventDetail?.eventDate.length - 1 === index) {
                    return (
                      " and " +
                      moment(formData?.eventDetail?.eventDate[index]).format(
                        "ddd"
                      )
                    );
                  } else {
                    if (index === 0) {
                      return moment(
                        formData?.eventDetail?.eventDate[index]
                      ).format("ddd");
                    } else {
                      return (
                        " " +
                        moment(formData?.eventDetail?.eventDate[index]).format(
                          "ddd"
                        )
                      );
                    }
                  }
                })
              : ""
          }`,
        });
      }
      options.push({
        value: `Recurs on the ${
          formData?.eventDetail?.eventDate.length === 1
            ? moment(formData?.eventDetail?.eventDate[0]).format("Do")
            : formData?.eventDetail?.eventDate?.length === 2
            ? moment(formData?.eventDetail?.eventDate[0]).format("Do") +
              " and " +
              moment(formData?.eventDetail?.eventDate[1]).format("Do")
            : formData?.eventDetail?.eventDate.length > 2
            ? formData?.eventDetail?.eventDate.map((d, index) => {
                if (formData?.eventDetail?.eventDate.length - 1 === index) {
                  return (
                    " and " +
                    moment(formData?.eventDetail?.eventDate[index]).format("Do")
                  );
                } else {
                  if (index === 0) {
                    return moment(
                      formData?.eventDetail?.eventDate[index]
                    ).format("Do");
                  } else {
                    return (
                      " " +
                      moment(formData?.eventDetail?.eventDate[index]).format(
                        "Do"
                      )
                    );
                  }
                }
              })
            : ""
        } of every month `,

        label: `Recurs on the ${
          formData?.eventDetail?.eventDate.length === 1
            ? moment(formData?.eventDetail?.eventDate[0]).format("Do")
            : formData?.eventDetail?.eventDate?.length === 2
            ? moment(formData?.eventDetail?.eventDate[0]).format("Do") +
              " and " +
              moment(formData?.eventDetail?.eventDate[1]).format("Do")
            : formData?.eventDetail?.eventDate.length > 2
            ? formData?.eventDetail?.eventDate.map((d, index) => {
                if (formData?.eventDetail?.eventDate.length - 1 === index) {
                  return (
                    " and " +
                    moment(formData?.eventDetail?.eventDate[index]).format("Do")
                  );
                } else {
                  if (index === 0) {
                    return moment(
                      formData?.eventDetail?.eventDate[index]
                    ).format("Do");
                  } else {
                    return (
                      " " +
                      moment(formData?.eventDetail?.eventDate[index]).format(
                        "Do"
                      )
                    );
                  }
                }
              })
            : ""
        }
        of every month`,
      });
    }
    if (
      formData?.eventDetail?.eventDate &&
      formData?.eventDetail?.eventDate.length === 0
    ) {
      options.push(
        { value: "Recurs every day", label: " Recurs every day" },
        {
          value: "Recurs every working day",
          label: "Recurs every working day",
        },
        { value: "Recurs every weekend", label: "Recurs every weekend" }
      );
    }
    return options;
  };
  return (
    <Formik
      initialValues={formData?.eventDetail}
      validationSchema={validationSchema}
    >
      {(formik) => {
        const { setFieldValue, values, handleChange, errors } = formik;
        newEvent(values);
        return (
          <Form className="addFormNew">
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <div className="row pe-lg-5">
                  <div className="col-md-12 pe-lg-5 ">
                    <div className="form-group mt-3">
                      <label className="py-2">
                        Name
                        <small className="ps-2">Make it short and sweet!</small>
                      </label>
                      <FormikControl
                        className={
                          "form-control" +
                          (showErrors && formErrors?.title
                            ? " validation-alert"
                            : "")
                        }
                        control="input"
                        type="text"
                        name="title"
                        placeholder="Enter Event name"
                        id="title"
                        value={formData?.eventDetail?.title}
                        onChange={(e) => {
                          values.title = e.target.value;
                          let data = { ...formData };
                          data.eventDetail.title = e.target.value;
                          setFormData(data);
                          setShowErrors(false);
                        }}
                        // onBlur={() => setShowErrors(false)}
                      />
                      {showErrors && formErrors.title ? (
                        <div className="danger">{formErrors.title}</div>
                      ) : null}
                    </div>
                    <div className="form-group mt-3">
                      <label className="py-2">Description</label>
                      <FormikControl
                        control="textarea"
                        rows={4}
                        className={
                          "form-control" +
                          (showErrors && formErrors?.description
                            ? " validation-alert"
                            : "")
                        }
                        id="description"
                        name="description"
                        value={formData?.eventDetail?.description}
                        onChange={(e) => {
                          values.description = e.target.value;
                          let data = { ...formData };
                          data.eventDetail.description = e.target.value;
                          setFormData(data);
                          setShowErrors(false);
                        }}
                        placeholder="Describe in short paragraph how volunteers can help"
                      />
                      {showErrors && formErrors.description ? (
                        <div className="danger">{formErrors.description}</div>
                      ) : null}
                    </div>
                    {/* <div className="form-group mt-3">
                      <label>Where do volunteers need to show up?</label>
                      {formData.eventDetail.isRemote === true ? (
                        ""
                      ) : (
                        <input
                          placeholder="Enter event location"
                          name="address"
                          id="address"
                          className="form-control"
                          type="text"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      )}
                      {location.lat && (
                        <div>
                          Latitude: {location.lat}
                          <br />
                          Longitude: {location.lng}
                          <br />
                          Postal Code: {location.postal_code}
                          <br />
                          City: {location.city}
                        </div>
                      )}
                    </div> */}
                    {/* Hide this on conditional */}
                    <div className="form-group mt-3">
                      <label>Where do volunteers need to show up?</label>
                      {formData.eventDetail.isRemote === true ? (
                        ""
                      ) : (
                        // <FormikControl
                        //   type="text"
                        //   placeholder="Enter event location"
                        //   name="address"
                        //   id="address"
                        //   className="form-control"
                        //   control="input"
                        //   // value={formData?.location}
                        //   onChange={(e) => {
                        //     values.address = e.target.value;
                        //     let data = { ...formData };
                        //     data.eventDetail.address = e.target.value;
                        //     setFormData(data);
                        //   }}
                        // />
                        <AutoAdress
                          showErrors={showErrors}
                          setShowErrors={setShowErrors}
                          formData={formData}
                          setFormData={setFormData}
                          formErrors={formErrors}
                        />
                      )}
                    </div>

                    {/* Hide till top comment if remote work is true */}

                    <div className="form-group mt-3">
                      <div className="form-control d-flex justify-content-between">
                        {formData?.eventDetail?.isRemote === true ? (
                          <label
                            title=""
                            htmlFor="custom-switch"
                            className="form-check-label"
                          >
                            This is a remote work opportunity
                          </label>
                        ) : (
                          <label
                            title=""
                            htmlFor="custom-switch"
                            className="form-check-label"
                            style={{ color: "#0000004d" }}
                          >
                            This is a remote work opportunity
                          </label>
                        )}

                        <div className="custom-control custom-switch">
                          <div className="form-check form-switch">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              checked={
                                formData?.eventDetail?.isRemote ? "checked" : ""
                              }
                              // disabled={
                              //   formData?.id && formData.eventDetail.isPublish
                              //     ? true
                              //     : false
                              // }
                              id="isRemote"
                              name="isRemote"
                              onChange={(e) => {
                                let remoteValue = e.target.checked;
                                values.isRemote = remoteValue;
                                let data = { ...formData };
                                data.eventDetail.isRemote = remoteValue;
                                setFormData(data);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Above condition enable the condition */}
                    {formData?.eventDetail?.isAnytime === true ? null : (
                      <div>
                        <div className="form-group mt-3">
                          <label className="pb-2">
                            When do volunteers need to show up?
                          </label>
                          {/* <FormikControl
                        control="date"
                        name="eventDate"
                        className="form-control"
                        id="eventDate"
                        clearIcon={null}
                        minDate={new Date()}
                        // onBlur={(e) => onHandleChange(e)}
                      /> */}

                          {/* From here the conditional work will start for date */}
                          <div className="date-picker-bg rounded">
                            {/* <DatePicker
                              // selected={formData?.eventDate || null}
                              // location
                              minDate={new Date()}
                              startDate={startDate}
                              endDate={endDate}
                              className="form-control py-5"
                              selectsRange
                              inline
                              id={"eventDate"}
                              filterDate={isWeekday}
                              onChange={(dates) => {
                                values.eventDate = dates;
                                setStartDate(dates[0]);
                                setEndDate(dates[1]);
                                setFormData((p) => ({
                                  ...p,
                                  ["eventDate"]: dates,
                                }));
                              }}
                              // value={values?.startDate || ""}
                            /> */}
                            <Calendar
                              format={"YYYY-MM-DD"}
                              multiple
                              name="eventDate"
                              id="eventDate"
                              mapDays={({ date }) => {
                                let isEventDateFind = false;
                                if (
                                  formData?.id &&
                                  formData.eventDetail.isPublish
                                ) {
                                  if (
                                    formData.eventDetail.eventDate.length > 0
                                  ) {
                                    let dates =
                                      formData.eventDetail.eventDate.map((d) =>
                                        moment(d).format("YYYY-MM-DD")
                                      );
                                    isEventDateFind = dates.includes(
                                      date.format()
                                    );
                                  }
                                  // if (isEventDateFind)
                                  //   return {
                                  //     disabled: true,
                                  //     style: {
                                  //       color: "#ccc",
                                  //       backgroundColor: "#000",
                                  //     },
                                  //   };
                                  // else {
                                  //   return {
                                  //     disabled: true,
                                  //   };
                                  // }
                                }
                              }}
                              value={
                                formData.eventDetail.eventDate.length > 0
                                  ? formData.eventDetail.eventDate
                                  : null
                              }
                              minDate={new Date()}
                              onChange={(dates) => {
                                let eventDates = [];
                                dates.map((date) => {
                                  let convertDate = date.format();
                                  eventDates.push(moment(convertDate).toDate());
                                });
                                values.eventDate = _.orderBy(eventDates);
                                let data = { ...formData };
                                data.eventDetail.eventDate =
                                  _.orderBy(eventDates);
                                data.eventDetail.recurringType = "";
                                if (data.eventDetail.isRecurring) {
                                  let recursOptions = opt();
                                  data.eventDetail.recurringType =
                                    recursOptions[1].value;
                                } else {
                                  data.eventDetail.recurringType = "";
                                }
                                setFormData(data);
                                setShowErrors(false);
                              }}
                            />
                          </div>
                          {showErrors && formErrors.dateError ? (
                            <div className="danger">{formErrors.dateError}</div>
                          ) : null}
                        </div>

                        <div className="form-group mt-1">
                          <div className="form-control d-flex justify-content-between">
                            {formData?.eventDetail?.isRecurring === true ? (
                              <Select
                                className="form-control p-0 pe-3 border-0"
                                placeholder="Please select"
                                // isDisabled={
                                //   formData?.id && formData.eventDetail.isPublish
                                //     ? true
                                //     : false
                                // }
                                value={{
                                  label:
                                    formData.eventDetail.recurringType ||
                                    "Please select",
                                  value:
                                    formData.eventDetail.recurringType || "",
                                }}
                                onChange={(e) => {
                                  const value = e.value;
                                  let data = { ...formData };
                                  data.eventDetail.recurringType = value;
                                  setFormData(data);
                                }}
                                styles={{
                                  backgroundColor: "#f4f4f4",

                                  control: (provided, state) => ({
                                    ...provided,
                                    padding: "0px",
                                    marginBlock: "0px",
                                    backgroundColor: "#f4f4f4",
                                    border: "none",
                                    height: "auto",
                                    border: 0,
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    fontWeight: state.isSelected
                                      ? "bold"
                                      : "normal",
                                    borderTop: "2px solid white",
                                    backgroundColor: state.isFocused
                                      ? "black"
                                      : "#f4f4f4",
                                    backgroundColor: state.isFocused
                                      ? "black"
                                      : "#f4f4f4",
                                    color: state.isFocused ? "white" : "black",
                                    border: "none",
                                    fontSize: state.selectProps.myFontSize,
                                    ":active": {
                                      ...provided[":active"],
                                      backgroundColor: "black",
                                    },
                                  }),
                                  singleValue: (provided, state) => ({
                                    ...provided,
                                    color: "black",
                                    fontSize: state.selectProps.myFontSize,
                                  }),
                                }}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "hotpink",
                                    primary: "black",
                                  },
                                })}
                                options={opt()}
                              />
                            ) : (
                              <label
                                title=""
                                htmlFor="custom-switch"
                                className="form-check-label py-2"
                                style={{ color: "#0000004d" }}
                              >
                                This is a recurring event
                              </label>
                            )}
                            {/* Conditionally handle the recursion 
if one date selected show it for every week or every month on that same day
if user selects series of days :show it for every week or every month on that same day
if the user select working days :show it for every week or every month on that same day
and hide other days for non-weekend */}

                            <div className="custom-control custom-switch pt-2">
                              <div className="form-check form-switch">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={formData?.eventDetail?.isRecurring}
                                  // disabled={
                                  //   formData?.id &&
                                  //   formData.eventDetail.isPublish
                                  //     ? true
                                  //     : false
                                  // }
                                  onChange={() => {
                                    let data = { ...formData };
                                    data.eventDetail.isRecurring =
                                      !formData.eventDetail.isRecurring;
                                    let recursOptions = opt();
                                    if (data.eventDetail.isRecurring) {
                                      data.eventDetail.recurringType =
                                        recursOptions[1].value;
                                    } else {
                                      data.eventDetail.recurringType = "";
                                    }
                                    setFormData(data);
                                    setShowErrors(false);
                                  }}
                                  id="time-slots"
                                  name="time-slots"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {showErrors && formErrors.recurringError ? (
                      <div className="danger">{formErrors.recurringError}</div>
                    ) : null}

                    {/* <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="form-group mt-3">
                          <label>Start time</label>
                          <FormikControl
                            format="hh:mm a"
                            className="form-control"
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={60}
                            timeCaption="Time"
                            id="startTime"
                            name="startTime"
                            control="date"
                            values={formData?.startTime}
                            // onBlur={(e) => onHandleChange(e)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="form-group mt-3">
                          <label>End time</label>
                          <FormikControl
                            format="hh:mm a"
                            className="form-control"
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={60}
                            timeCaption="Time"
                            id="endTime"
                            name="endTime"
                            control="date"
                            value={formData?.endTime}
                            onBlur={(e) => onHandleChange(e)}
                          />
                        </div>
                      </div>
                    </div> */}

                    {/* If work perform anytime then all options related to calender become hide */}
                    <div className="form-group mt-3">
                      <div className="form-control d-flex justify-content-between">
                        {formData?.eventDetail?.isAnytime === true ? (
                          <label
                            title=""
                            htmlFor="custom-switch"
                            className="form-check-label"
                          >
                            Work can be performed anytime
                          </label>
                        ) : (
                          <label
                            title=""
                            htmlFor="custom-switch"
                            className="form-check-label"
                            style={{ color: "#0000004d" }}
                          >
                            Work can be performed anytime
                          </label>
                        )}

                        <div className="custom-control custom-switch">
                          <div className="form-check form-switch">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              // disabled={
                              //   formData?.id && formData.eventDetail.isPublish
                              //     ? true
                              //     : false
                              // }
                              checked={
                                formData?.eventDetail?.isAnytime
                                  ? "checked"
                                  : ""
                              }
                              onChange={(e) => {
                                let anytimeValue = e.target.checked;
                                values.isAnytime = anytimeValue;
                                let data = { ...formData };
                                data.eventDetail.isAnytime = anytimeValue;
                                setFormData(data);
                              }}
                              id="isAnytime"
                              name="isAnytime"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* {isChecked === true ? (
                      <div className="form-group mt-3">
                        <div className="form-control">
                          <select
                            id="increments"
                            value={formData?.increments}
                            className="form-select"
                            name="increments"
                            onChange={(e) =>
                              setFormData((p) => ({
                                ...p,
                                [e.target.name]: e.target.value,
                              }))
                            }
                          >
                            {HourIncrements.HourIncrements.map((result) => (
                              <option
                                key={result.id}
                                text={result.id}
                                value={result.value}
                              >
                                {result.Name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    ) : null} */}
                  </div>
                </div>
              </div>
              <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-12"></div>
              <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 pe-xl-5">
                <div className="form-group mt-3">
                  <label label htmlFor="eventtags">
                    Tag your event
                  </label>
                  <EventTags
                    callback={setTags}
                    setFormData={setFormData}
                    formData={formData}
                    type="event"
                  />
                </div>
                {/* <UplaodImage setFormData={setFormData} formData={formData} /> */}
              </div>
              {/* <div>
                {" "}
                <FileResult />
              </div> */}
              {/* <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                <Tagyourevent setFormData={setFormData} formData={formData} />
              </div> */}
            </div>
            {/* <div className="button-NewEvent mt-5">
          <button
            type="button"
            className="btn submit-button"
            onClick={manageActiveTab}
          >
            Next
          </button>
          <button
            type="submit"
            className="btn save-draft"
            disabled={!formik.isValid}
          >
            Save as draft
          </button>
        </div> */}
          </Form>
        );
      }}
    </Formik>
  );
};

export default NewEvent;
