import React, { useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
const UpcomingEvent = ({ upcomingEvent }) => {
  // const [upcomingEvent, setUpcomingEvent] = useState({
  //   eventName: "Cook out for the homeless",
  //   eventType: "Upcoming",
  //   eventDate: "Tuesday, Feb 3",
  //   task: 3,
  //   volunteers: 12,
  //   recruited: 4,
  //   recruitedRate: 66,
  // });
  const getID = upcomingEvent;
  console.log(getID, "id");
  return (
    <div
      className="upcoming-event-card my-5 rounded"
      style={
        upcomingEvent?.result?.recruitedRate > 90
          ? { backgroundColor: "#dff7e3" }
          : upcomingEvent?.result?.recruitedRate > 50
          ? { backgroundColor: "#ffedcb" }
          : { backgroundColor: "#ffe5e3" }
      }
    >
      <div className="d-flex flex-sm-row flex-column justify-content-between">
        <div>
          <p className="my-0">
            {"Upcoming"} -
            {upcomingEvent?.result?.eventDate.length > 0
              ? upcomingEvent?.result?.eventDate.map((date, index) => {
                  return (
                    <span key={index}>
                      {upcomingEvent?.result?.eventDate - 1 !== index
                        ? moment(date).format("dddd, MMM DD") + ", "
                        : moment(date).format("dddd, MMM DD")}
                    </span>
                  );
                })
              : moment().format("dddd, MMM DD")}
          </p>
          <h4 className="page-Title">{upcomingEvent?.result?.title}</h4>
        </div>
        <div className="my-1">
          <Link
            className="manage-btn"
            to={`/event/detail/${upcomingEvent?.result?.eventId}`}
          >
            Manage Event
          </Link>
        </div>
      </div>

      <div className="rounded-btns d-flex flex-sm-row flex-column py-2">
        <div className="tasks-btn my-1">
          {upcomingEvent?.result?.taskCount} Tasks
        </div>
        <div className="tasks-btn my-1">
          {upcomingEvent?.result?.volunteerNeed} Volunteers needed
        </div>
        <div className="recruited-btn my-1">
          {upcomingEvent?.result?.recruited} Recruited
        </div>
        <div className="rate-btn my-1">
          {upcomingEvent?.result?.recruitedRate}% Recruitment rate
        </div>
      </div>
    </div>
  );
};

export default UpcomingEvent;
