import { USER_PROFILE, USER_TOKEN } from "../constants/profileConstant";
const INITIAL_STATE = {
  profile: {},
  token: "",
};
export const profileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_PROFILE:
      return { ...state, profile: { ...action.payload } };
    case USER_TOKEN:
      return { ...state, token: { ...action.payload } };
    default:
      return state;
  }
};
