import axios from "axios";
import { USER_PROFILE, USER_TOKEN } from "../constants/profileConstant";

export const setProfileAction = (data) => {
  return {
    type: USER_PROFILE,
    payload: data,
  };
};

export const setTokenAction = (token) => {
  return {
    type: USER_TOKEN,
    payload: token,
  };
};
