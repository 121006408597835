import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import InfiniteScroll from "react-infinite-scroll-component";
import { APPLICATIONS_LIST_LENGTH } from "../constant";
import Loader from "./Loader";
import { ApproveApplication } from "../Services/EventService";
import { useParams } from "react-router-dom";

const ApplicantList = ({
  Tasks,
  applicants,
  Schdules,
  setSelectedSchdules,
  setSelectedTasks,
  selectedTask,
  selectedSchedule,
  fetchMoreData,
  volunteersRecruited,
  volunteers,
  eventApplications,
  eventId,
  setEventApplications,
  type,
  eventSelectedDate,
  getEventApplications,
}) => {
  console.log(applicants);
  const [isLoading, setIsLoading] = useState("");
  let { eventID } = useParams();
  const taskOptions = (volunteerTasks) => {
    let options = [];
    if (volunteerTasks && volunteerTasks.length > 0) {
      volunteerTasks.map((task, index) => {
        options.push({
          value: task?.id,
          label: task?.title,
        });
      });
    }
    return options;
  };
  const sessionOptions = (volunteerSessions) => {
    let options = [];
    if (volunteerSessions && volunteerSessions.length > 0) {
      volunteerSessions.map((task, index) => {
        let date = moment().format("YYYY-MM-DD");
        options.push({
          value: task?.id,
          label:
            moment(task?.startTime, "hh:mm").format("hh:mm A") +
            " - " +
            moment(task?.endTime, "hh:mm").format("hh:mm A"),
        });
      });
    }
    return options;
  };
  const initTaskValue = (volunteerTasks) => {
    if (!selectedTask) {
      let tasksOptions = taskOptions(volunteerTasks);
      if (tasksOptions.length > 0) {
        setSelectedTasks(tasksOptions[0]);
        return tasksOptions[0];
      }
    }
    return selectedTask;
  };
  const initScheduleValue = (volunteerSessions) => {
    if (!selectedSchedule) {
      let sessionsOptions = sessionOptions(volunteerSessions);
      if (sessionsOptions.length > 0) {
        setSelectedSchdules(sessionsOptions[0]);
        return sessionsOptions[0];
      }
    }
    return selectedSchedule;
  };
  const submitApplication = async (event, isApprove) => {
    if (isLoading !== "") {
      return;
    }
    setIsLoading(event.id);
    let taskId = "";
    let sessionId = "";
    let selectedDate = "";
    let findSelectedTask = event.volunteerTasks.find((x) => x.selectedTaskId);
    let findSessionTask = event.volunteerSessions.find(
      (x) => x.selectedSessionId
    );
    if (!findSelectedTask) {
      taskId = event?.volunteerTasks[0]?.id;
    } else {
      taskId = findSelectedTask?.selectedTaskId;
    }
    if (!findSessionTask) {
      sessionId = event?.volunteerSessions[0]?.id;
    } else {
      sessionId = findSessionTask?.selectedSessionId;
    }
    if (!eventSelectedDate) {
      selectedDate = eventApplications?.events[0]?.eventDate;
    } else {
      selectedDate = eventSelectedDate;
    }
    let data;
    if (sessionId) {
      data = {
        userID: event?.id,
        eventID: eventId,
        taskID: [taskId],
        taskSessionID: [sessionId],
        selectedDate: [moment(selectedDate).format("YYYY-MM-DD")],
        isApprove: isApprove,
      };
    } else {
      data = {
        userID: event?.id,
        eventID: eventId,
        taskID: [taskId],
        selectedDate: [moment(selectedDate).format("YYYY-MM-DD")],
        isApprove: isApprove,
      };
    }

    let res = await ApproveApplication(data);
    if (res.length > 0 && res[0].code) {
    } else {
      getEventApplications();
      // setIsLoading("");
      // window.location.href = "/";
    }
    setIsLoading("");
  };
  const setTask = (task, applicantDetail) => {
    let eventApplicationsClone = { ...eventApplications };
    let tasks = eventApplications?.volunteers[type];
    let findTaskIdnex = tasks.findIndex((t) => t.id === applicantDetail.id);

    let findIdnex = tasks[findTaskIdnex].volunteerTasks.findIndex(
      (t) => t.id === task.value
    );
    if (findIdnex > -1) {
      tasks[findTaskIdnex].volunteerTasks.map((session) => {
        delete session?.selectedTaskId;
      });
      tasks = tasks;
      tasks[findTaskIdnex].volunteerTasks[findIdnex].selectedTaskId =
        task.value;
      eventApplicationsClone.volunteers[type] = tasks;
      setEventApplications(eventApplicationsClone);
    }
  };
  const setSession = (task, applicantDetail) => {
    let eventApplicationsClone = { ...eventApplications };
    let tasks = eventApplications?.volunteers[type];
    let findTaskIdnex = tasks.findIndex((t) => t.id === applicantDetail.id);

    let findIdnex = tasks[findTaskIdnex].volunteerSessions.findIndex(
      (t) => t.id === task.value
    );
    if (findIdnex > -1) {
      tasks[findTaskIdnex].volunteerSessions.map((session) => {
        delete session?.selectedSessionId;
      });
      tasks = tasks;
      tasks[findTaskIdnex].volunteerSessions[findIdnex].selectedSessionId =
        task.value;
      eventApplicationsClone.volunteers[type] = tasks;
      setEventApplications(eventApplicationsClone);
    }
  };
  return (
    <>
      <InfiniteScroll
        next={fetchMoreData}
        dataLength={applicants.length}
        hasMore={applicants.length < APPLICATIONS_LIST_LENGTH ? false : true}
        loader={APPLICATIONS_LIST_LENGTH < applicants.length ? "" : <Loader />}
      >
        <div className="event-detail-table">
          <table className="table align-middle">
            <tbody>
              {applicants.length > 0 ? (
                applicants.map((applicantDetail, i) => (
                  <tr key={i} className="py-5">
                    <td>
                      <img
                        src={
                          applicantDetail.image
                            ? applicantDetail.image
                            : "https://verodevelopapi.atcommerce.pk/wwwroot/UserImages/default_avatar.svg"
                        }
                        className="rounded-circle me-2"
                        style={{ height: "35px", width: "35px" }}
                      />
                    </td>
                    <td>{applicantDetail.name}</td>
                    <td>
                      <a
                        className="link-to"
                        href={"tel:" + applicantDetail.phoneNumber}
                      >
                        {applicantDetail.phoneNumber}
                      </a>
                    </td>

                    <td>
                      <a
                        className="link-to"
                        href={"mailto:" + applicantDetail.emailAddress}
                      >
                        {applicantDetail.emailAddress}
                      </a>
                    </td>
                    <td className="py-2">
                      {applicantDetail?.volunteerTasks.length > 0 && (
                        <Select
                          styles={{
                            backgroundColor: "white",

                            control: (provided, state) => ({
                              ...provided,
                              padding: "0px",
                              marginBlock: "0px",
                              backgroundColor: "white",
                              border: "none",
                              minHeight: "25px",
                              border: 0,
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              fontWeight: state.isSelected ? "bold" : "normal",
                              backgroundColor: state.isFocused
                                ? "black"
                                : "white",
                              backgroundColor: state.isFocused
                                ? "black"
                                : "white",
                              border: state.isFocused ? "none" : "none",
                              color: state.isFocused ? "white" : "black",
                              border: "none",
                              fontSize: state.selectProps.myFontSize,
                              ":active": {
                                ...provided[":active"],
                                backgroundColor: "black",
                              },
                            }),
                            singleValue: (provided, state) => ({
                              ...provided,
                              color: "black",
                              fontSize: state.selectProps.myFontSize,
                            }),
                          }}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary25: "hotpink",
                              primary: "black",
                            },
                          })}
                          defaultValue={
                            selectedTask ||
                            initTaskValue(applicantDetail?.volunteerTasks)
                          }
                          onChange={(task) => {
                            setTask(task, applicantDetail);
                            // setSelectedTasks(task);
                          }}
                          options={taskOptions(applicantDetail?.volunteerTasks)}
                        />
                      )}
                    </td>
                    <td className="py-2">
                      {applicantDetail?.volunteerSessions.length > 0 && (
                        <Select
                          styles={{
                            backgroundColor: "white",
                            control: (provided, state) => ({
                              ...provided,
                              padding: "0px",
                              marginBlock: "0px",
                              backgroundColor: "white",
                              border: "none",
                              minHeight: "25px",
                              border: 0,
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              fontWeight: state.isSelected ? "bold" : "normal",
                              backgroundColor: state.isFocused
                                ? "black"
                                : "white",
                              backgroundColor: state.isFocused
                                ? "black"
                                : "white",
                              border: state.isFocused ? "none" : "none",
                              color: state.isFocused ? "white" : "black",
                              border: "none",
                              fontSize: state.selectProps.myFontSize,
                              ":active": {
                                ...provided[":active"],
                                backgroundColor: "black",
                              },
                            }),
                            singleValue: (provided, state) => ({
                              ...provided,
                              color: "black",
                              fontSize: state.selectProps.myFontSize,
                            }),
                          }}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary25: "hotpink",
                              primary: "black",
                            },
                          })}
                          defaultValue={initScheduleValue(
                            applicantDetail?.volunteerSessions
                          )}
                          onChange={(task) => {
                            setSession(task, applicantDetail);

                            setSelectedSchdules(task);
                          }}
                          options={sessionOptions(
                            applicantDetail?.volunteerSessions
                          )}
                        />
                      )}
                    </td>
                    <td className="d-flex flex-row-reverse flex-lg-row">
                      {applicantDetail?.badges?.map((badge) => {
                        return (
                          <div className="py-2">
                            <img
                              src={badge}
                              className="rounded-circle "
                              style={{ height: "35px", width: "35px" }}
                            />
                          </div>
                        );
                      })}
                    </td>
                    <td className="Apr-btn ">
                      {!applicantDetail?.isApproved ? (
                        <Link
                          style={{
                            color: "#FF1B11",
                            fontWeight: "500",
                            textDecoration: "none",
                            float: "right",
                          }}
                          onClick={() =>
                            submitApplication(applicantDetail, true)
                          }
                          disabled={isLoading == ""}
                        >
                          Approve
                          {applicantDetail.id == isLoading && (
                            <div
                              className="spinner-border text-dark ms-2"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          )}
                        </Link>
                      ) : (
                        <Link
                          style={{
                            color: "#0000004d",
                            fontWeight: "500",
                            textDecoration: "none",
                            float: "right",
                          }}
                          onClick={() =>
                            submitApplication(applicantDetail, false)
                          }
                          disabled={isLoading == ""}
                        >
                          Remove
                          {applicantDetail.id == isLoading && (
                            <div
                              className="spinner-border text-dark ms-2"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          )}
                        </Link>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <>
                  {/* {volunteersRecruited?.recruited.length > 0 ||
                  volunteers?.applications.length === 0 ? (
                    <>
                      {volunteersRecruited?.recruited.length === 0 ||
                      volunteers?.applications.length > 0 ? (
                        ""
                      ) : (
                        <p className="mt-4">No record found</p>
                      )}
                    </>
                  ) : (
                    <p className="mt-4">
                      You haven’t approved any volunteers yet, head over to the
                      “Applications” tab to review and approve volunteers for
                      this event
                    </p>
                  )} */}

                  {type === "recruited" ? (
                    <p className="mt-4">
                      You haven’t approved any volunteers yet, head over to the
                      “Applications” tab to review and approve volunteers for
                      this event
                    </p>
                  ) : (
                    <p className="mt-4">
                      No volunteer applied on this event yet
                    </p>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </InfiniteScroll>
    </>
  );
};

export default ApplicantList;
